import { createAction, props } from '@ngrx/store';
import { Cut } from '../../models/cut';
import { Update } from '@ngrx/entity';
import { Comment } from '../../models/comment';


export const loadCuts = createAction('[Cut/API] Load Cuts ', props<{ organizationId: string, siteId: string}>());
export const loadCutsSuccess = createAction('[Cut/API] Load Cuts success', props<{ cuts: Cut[] }>());
export const loadCutsFailure = createAction('[Cut/API] Load Cuts failure', props<{ error: any }>());

export const addCut = createAction('[Cut] Add Cut ', props<{ organizationId: string, siteId: string, point1: Array<number>, point2: Array<number> }>());
export const addCutSuccess = createAction('[Cut/API] Add Cut success', props<{ cut: Cut}>());
export const addCutFailure = createAction('[Cut/API] Add Cut failure', props<{ error: any }>());

export const updateCut = createAction('[Cut/API] Update Cut ', props<{ organizationId: string, siteId: string, cutId: string, payload: any }>());
export const updateCutSuccess = createAction('[Cut/API] Update Cut success', props<{ update: Update<any> }>());
export const updateCutFailure = createAction('[Cut/API] Update Cut failure', props<{ error: any }>());

export const deleteCut = createAction('[Cut] Delete Cut', props<{ organizationId: string, siteId: string, cutId: string }>());
export const deleteCutSuccess = createAction('[Cut/API] Delete Cut success', props<{ id: string }>());
export const deleteCutFailure = createAction('[Cut/API] Delete Cut failure', props<{ error: any }>());

export const clearAllCuts = createAction('[Cut] Clear All Cuts');
export const selectCut = createAction('[Cut] Select Cut', props<{ id: string | undefined}>());
export const exportCutDXF = createAction('[Cut] Export Cut DXF', props<{ organizationId: string, siteId: string, cuts: Array<any>, slope: Array<Array<number>>, language_code: string}>());
export const exportCutDXFSuccess = createAction('[Cut/API] Export Cut DXFsuccess', props<{ dxf: string}>());
export const exportCutDXFFailure = createAction('[Cut/API] Export Cut DXF failure', props<{ error: any }>());

export const loadCutComments = createAction('[Cut/API] Load Cut Comments ', props<{ organizationId: string, siteId: string, cutId: string  }>());
export const loadCutCommentsSuccess = createAction('[Cut/API] Load Cut Comments success', props<{ comments: Array<Comment> }>());
export const loadCutCommentsFailure = createAction('[Cut/API] Load Cut Comments failure', props<{ error: any }>());

export const addCutComment = createAction('[Cut/API] Add Cut Comment ', props<{ organizationId: string, siteId: string, cutId: string, payload: any }>());
export const addCutCommentSuccess = createAction('[Cut/API] Add Cut Comment success', props<{ comment: Comment }>());
export const addCutCommentFailure = createAction('[Cut/API] Add Cut Comment failure', props<{ error: any }>());
