<mat-card *ngIf="marker"
          style="cursor: pointer;"
          [style.border]=border
          (click)="onClickEdit(marker)">

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{marker.name}}</mat-card-title>
  </mat-card-header>
  <div *ngIf="marker.isShared && (currentUser$ | async) as currentUser" class="shared-item-indicators">
    <mat-icon *ngIf="currentUser.name == marker.author">person</mat-icon>
    <mat-icon>share</mat-icon>
  </div>
</mat-card>



