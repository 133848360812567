import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromTilesetConfig from './tileset-config.reducer';


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromTilesetConfig.adapter.getSelectors();

export const selectTilesetConfigState = createFeatureSelector<fromTilesetConfig.State>('tilesetConfigs');

export const selectTilesetConfigIds = createSelector(
  selectTilesetConfigState,
  selectIds
);
export const selectTilesetConfigEntities = createSelector(
  selectTilesetConfigState,
  selectEntities
);
export const selectAllTilesetConfigs = createSelector(
  selectTilesetConfigState,
  selectAll
);
export const selectTilesetConfigTotal = createSelector(
  selectTilesetConfigState,
  selectTotal
);

export const selectCurrentTilesetConfigId = createSelector(
  selectTilesetConfigState,
  fromTilesetConfig.getSelectedTilesetConfigId
);

export const selectCurrentTilesetConfig = createSelector(
  selectTilesetConfigEntities,
  selectCurrentTilesetConfigId,
  (entities, id) => (id) ? entities[id] : null
);
