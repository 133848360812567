import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectAllElevations
} from '../../../../features/elevation/elevation.selectors';
import { Elevation } from '../../../../models/elevation';


@Component({
  selector: 'elevation-list',
  templateUrl: './elevation-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class ElevationListComponent {

  constructor(private store: Store) {}

  get elevations$(): Observable<Array<Elevation>> {
    return this.store.select(selectAllElevations);
  }
}
