import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Detection } from '../../../../models/detection';
import {
  selectCurrentDetection,
  selectDetectionEmergencyTemplate,
  selectDetectionTemplate
} from '../../../../features/detection/detection.selectors';
import {
  addDetectionEmergency,
  deleteDetection,
  selectDetection,
  updateDetection,
} from '../../../../features/detection/detection.actions';
import { selectMeasure } from '../../../../features/measure/measure.actions';
import {
  selectVolume
} from '../../../../features/volume/volume.actions';
import { selectCut } from '../../../../features/cut/cut.actions';
import {
  selectMarker
} from '../../../../features/marker/marker.actions';
import { ExportTools } from '../../../../tools/export.tools';
import { CesiumTools } from '../../../../tools/cesium.tools';
import {
  setCameraConfiguration,
  setCameraMode
} from '../../../../features/cesium/cesium.actions';
import { CameraMode } from '../../../../enums/camera';
import { EmergencyTemplate } from '../../../../models/emergency-template';
import { combineLatest, first } from 'rxjs';
import { DetectionMode, DetectionStatus } from '../../../../enums/detection';
import { FormControl, FormGroup } from '@angular/forms';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { Site } from '../../../../models/site';
import { selectUser } from '../../../../features/user/user.selectors';
import { User } from '../../../../models/user';
import {
  DeleteDetectionDialog
} from '../../../dialogs/delete-detection-dialog/delete-detection.dialog';
import { MatDialog } from '@angular/material/dialog';
import { EmergencyTools } from '../../../../tools/emergency.tools';
import { DetectionTemplate } from '../../../../models/detection-template';
import { ApiService } from '../../../../services/api.service';
import { TranslocoService } from '@jsverse/transloco';
import { ItemNamePipe } from '../../../pipes/tools.pipe';
import {
  selectElevation
} from '../../../../features/elevation/elevation.actions';



@Component({
  selector: 'detection-details',
  templateUrl: './detection-details.component.html',
  styleUrls: ['../details.component.scss', './detection-details.component.scss'],
})
export class DetectionDetailsComponents {

  detection: Detection | null | undefined;
  detectionStatusEnum = DetectionStatus;
  emergencyTemplate: EmergencyTemplate | undefined;
  detectionTemplate: DetectionTemplate | undefined;

  statusFormControl = new FormControl();
  typeFormControl = new FormControl();
  emeregencyFormControl = new FormControl();
  form = new FormGroup({
    status: this.statusFormControl,
    type: this.typeFormControl,
    emergency: this.emeregencyFormControl
  });
  formHasChanged: boolean = false;

  constructor(private store: Store,
              private api: ApiService,
              private dialog: MatDialog,
              private translocoService: TranslocoService,
              private itemNamePipe: ItemNamePipe) {
    this.initHandleCurrentDetection();
    this.initHandleEmergencyTemplate();
    this.initHandleDetectionTemplate();
  }

  private initHandleCurrentDetection(): void {
    this.store.select(selectCurrentDetection)
      .pipe(takeUntilDestroyed())
      .subscribe((detection: Detection | null | undefined) => {
        this.detection = detection;
        if (this.detection) {
          this.store.dispatch(selectMeasure({id: undefined}));
          this.store.dispatch(selectVolume({id: undefined}));
          this.store.dispatch(selectMarker({id: undefined}));
          this.store.dispatch(selectCut({id: undefined}));
          this.store.dispatch(selectElevation({id: undefined}));

          this.statusFormControl.setValue(this.detection.status);
          this.typeFormControl.setValue(this.detection.label);
          const emergencyPriority = (this.detection.emergency) ? this.detection.emergency.priority : 1;
          this.emeregencyFormControl.setValue(emergencyPriority.toString());

          this.form.valueChanges.subscribe( (data: any) => {
            const statusChanged: boolean = data['status'] != this.detection?.status;
            const typeChanged: boolean = data['type'] != this.detection?.label;
            let emergencyChanged: boolean = false;
            if (data['status'] == DetectionStatus.CONFIRMED) {
              emergencyChanged = (this.detection?.emergency) ? data['emergency'] != this.detection?.emergency.priority : true;
            }
            this.formHasChanged = statusChanged || typeChanged || emergencyChanged;
          });
        }
      });
  }

  private initHandleEmergencyTemplate(): void {
    this.store.select(selectDetectionEmergencyTemplate)
      .pipe(takeUntilDestroyed())
      .subscribe((emergencyTemplate: EmergencyTemplate | undefined) => {
        this.emergencyTemplate = emergencyTemplate
      });
  }

  private initHandleDetectionTemplate(): void {
    this.store.select(selectDetectionTemplate)
      .pipe(takeUntilDestroyed())
      .subscribe((detectionTemplate: DetectionTemplate | undefined) => {
        this.detectionTemplate = detectionTemplate
      });
  }

  private updateDetection(site: Site, detection: Detection): void {
    this.store.dispatch(updateDetection({
      organizationId: site.organization,
      siteId: site.id,
      batchId: site.lastBatch,
      detectionId: detection.id,
      payload: {
        'status': this.statusFormControl.value,
        'label': this.typeFormControl.value,
      }
    }));
  }

  private updateEmergency(site: Site, user: User, detection: Detection): void {
    if (this.emergencyTemplate) {
      const etl = this.emergencyTemplate.labels
        .find(i =>  {
          return i.priority.toString() === this.emeregencyFormControl.value
        });
      if (etl) {
        this.store.dispatch(addDetectionEmergency({
          organizationId: site.organization,
          siteId: site.id,
          batchId: site.lastBatch,
          detectionId: detection.id,
          payload: {
            'creator': user.name,
            'emergency_level': etl.level,
            'emergency_label': etl.label,
            'emergency_priority': etl.priority
          }
        }));
      }
    }
  }

  emergencyLevelColor(level: number): string {
    return EmergencyTools.textColor(level);
  }

  get confidence(): number {
    if (this.detection?.createMode == DetectionMode.USER) {
      return 100.0;
    }
    else {
      return (this.detection?.confidence) ? this.detection.confidence * 100.0 : 0
    }
  }

  get evolution(): number {
    return (this.detection?.evolution) ? this.detection.evolution * 100.0 : 0
  }

  onClickFlyTo(detection: Detection): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(detection.center, detection.cameraDirection, detection.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickClose(): void {
    this.store.dispatch(selectDetection({id: undefined}));
  }

  onClickExportPicture(detection: Detection): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.api.imageTmpUrl$(site.organization, site.id, site.lastBatch, detection.resource)
            .pipe(first()).subscribe(url => {
              ExportTools.exportResourceUrl(detection.resource, url);
          });
        }
      });
  }

  onClickExportDXF(detection: Detection): void {

  }

  onClickExportData(detection: Detection): void {
    const name = this.itemNamePipe.transform(detection.label, detection.id);
    ExportTools.exportJSON(name, JSON.stringify(detection));
  }

  onClickUpdate(detection: Detection): void {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectUser),
    ]).pipe(first())
      .subscribe(results => {
        const site: Site | null | undefined = results[0];
        const user: User | undefined = results[1];
        if (site) {
          this.updateDetection(site, detection);
          if (user && (!detection.emergency || detection.emergency.priority != this.emeregencyFormControl.value)) {
            this.updateEmergency(site, user, detection);
          }
        }
      });
  }

  onClickDelete(detection: Detection): void {
    const deleteDialogRef = this.dialog.open(DeleteDetectionDialog, {
      disableClose: false
    });
    deleteDialogRef.componentInstance.message = this.translocoService.translate("would_you_like_to_delete", {name: detection.name});
    deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(deleteDetection({
                organizationId: site.organization,
                siteId: site.id,
                batchId: site.lastBatch,
                detectionId: detection.id,
                payload: {
                  'label': detection.label,
                  'status': result
                }}));
            }
          });
      }
    });
  }
}
