import {
  autoserialize,
  autoserializeAs,
  deserialize, deserializeAs,
} from 'cerialize';

export class MarkerImage {
  @autoserializeAs("image_name") name!: string;
  @autoserializeAs("marker_pixel") position!: Array<number>;
  @autoserializeAs("center_distance") distance!: number;
}

export class MarkerBatchData {
  @autoserialize batch!: string;
  @autoserializeAs(MarkerImage) data!: Array<MarkerImage>;
}

export class Marker {
  kind: string = 'marker';
  @autoserialize id!: string;
  @autoserialize name!: string;
  @autoserialize author!: string;
  @autoserialize point!: Array<number>;
  @autoserializeAs(MarkerBatchData) data!: Array<MarkerBatchData>;
  @deserialize center!: Array<number>;
  @deserializeAs('camera_direction') cameraDirection!: Array<number>;
  @deserialize length!: number;
  @deserializeAs('is_shared') isShared!: boolean;
  show: boolean;

  constructor() {
    this.show = true;
  }
}
