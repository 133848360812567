import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { setMapHandleKeyboard } from '../../../features/cesium/cesium.actions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  setConfigTilesetColorRampMax,
  setConfigTilesetColorRampMin,
  setConfigTilesetStyleColorConditions
} from '../../../features/config/config.actions';
import { ColorTools } from '../../../tools/color.tools';
import { TilesetConfig } from '../../../models/tileset-config';
import {
  selectCurrentTilesetConfig
} from '../../../features/tileset-config/tileset-config.selectors';

@Component({
  selector: 'tileset-style-form',
  templateUrl: './tileset-style-form.component.html',
  styleUrls: ['./tileset-style-form.component.scss'],
})
export class TilesetStyleFormComponent {

  tilesetConfig: TilesetConfig | null | undefined;
  tilesetFormGroup = new FormGroup({
    min: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0),
    ]),
    max: new FormControl<number>(1, [
      Validators.required,
      Validators.min(0.1),
    ]),
  });

  constructor(private store: Store) {
    this.initHandleTilesetConfig();
  }

  private initHandleTilesetConfig(): void {
    this.store.select(selectCurrentTilesetConfig)
      .pipe(takeUntilDestroyed())
      .subscribe((tilesetConfig: TilesetConfig | null | undefined) => {
        const max = (tilesetConfig) ? Math.round(tilesetConfig.maxDistance * 10) / 10 : 1;
        this.tilesetFormGroup.controls.max.setValue(max);
        setTimeout(()=>{
          this.updateTilsetStyleColorConditions();
        }, 1);
      });
  }

  onInputHandleFocus(event: any) {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }
  onInputLeaveFocus(event: any) {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  updateTilsetStyleColorConditions(): void {
    if(!this.tilesetFormGroup.valid) return;
    const min = this.tilesetFormGroup.controls.min.value || 0;
    const max = this.tilesetFormGroup.controls.max.value || 1;
    const conditions = ColorTools.tilesetStyleColorConditions(min, max, 20);
    this.store.dispatch(setConfigTilesetStyleColorConditions({
      conditions
    }));
    this.store.dispatch(setConfigTilesetColorRampMin({
      value: min
    }));
    this.store.dispatch(setConfigTilesetColorRampMax({
      value: max
    }));
  }
}
