import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as MarkerActions from './marker.actions';
import { Deserialize } from 'cerialize';
import { Marker } from '../../models/marker';
import { ApiService } from '../../services/api.service';
import { TranslocoService } from '@jsverse/transloco';
import { Comment } from '../../models/comment';


@Injectable()
export class MarkerEffects {

  loadMarkers$ = createEffect(() => {
    return this.actions$.pipe(ofType(MarkerActions.loadMarkers),
      concatMap(({organizationId, siteId}) =>
        this.api.markers$(organizationId, siteId).pipe(
          map(markers => markers.map((marker: any) => {
            const obj: Marker = Deserialize(marker, Marker);
            if (!obj.name) {
              obj.name = this.translocoService.translate('marker') + " " + obj.id.slice(0,4).toUpperCase();
            }
            return obj;
          })),
          map(markers => MarkerActions.loadMarkersSuccess({ markers })),
          catchError(error => of(MarkerActions.loadMarkersFailure({ error }))))
      ));
  });

  addMarker$ = createEffect(() => {
    return this.actions$.pipe(ofType(MarkerActions.addMarker),
      concatMap(({organizationId, siteId, point, cameraPosition}) =>
      this.api.addMarker$(organizationId, siteId, point, cameraPosition).pipe(
        map(marker => {
          const obj: Marker = Deserialize(marker, Marker);
          obj.name = this.translocoService.translate('marker') + " " + obj.id.slice(0,4).toUpperCase();
          return obj;
        }),
        map(marker => MarkerActions.addMarkerSuccess({ marker })),
        catchError(error => of(MarkerActions.addMarkerFailure({ error }))))
    ));
  });

  deleteMarker$ = createEffect(() => {
    return this.actions$.pipe(ofType(MarkerActions.deleteMarker),
      concatMap(({organizationId, siteId, markerId}) =>
        this.api.deleteMarker$(organizationId, siteId, markerId).pipe(
          map(marker =>  MarkerActions.deleteMarkerSuccess({ id: markerId })),
          catchError(error => of(MarkerActions.deleteMarkerFailure({ error }))))
      ));
  });

  updateMarker$ = createEffect(() => {
    return this.actions$.pipe(ofType(MarkerActions.updateMarker),
      concatMap(({organizationId, siteId, markerId, payload}) =>
        this.api.updateMarker$(organizationId, siteId, markerId, payload).pipe(
          map(marker => Deserialize(marker, Marker)),
          map(marker => {
            return {id: marker.id, changes: marker};
          }),
          map(update => MarkerActions.updateMarkerSuccess({ update })),
          catchError(error => of(MarkerActions.updateMarkerFailure({ error }))))
      ));
  });

  loadMarkerComments$ = createEffect(() => {
    return this.actions$.pipe(ofType(MarkerActions.loadMarkerComments),
      concatMap(({organizationId, siteId, markerId}) =>
        this.api.markerComments$(organizationId, siteId, markerId).pipe(
          map(comments => comments.map((comment: any) => Deserialize(comment, Comment))),
          map(comments => MarkerActions.loadMarkerCommentsSuccess({ comments })),
          catchError(error => of(MarkerActions.loadMarkerCommentsFailure({ error }))))
      ));
  });

  addMarkerComment$ = createEffect(() => {
    return this.actions$.pipe(ofType(MarkerActions.addMarkerComment),
      concatMap(({organizationId, siteId, markerId, payload}) =>
        this.api.addMarkerComment$(organizationId, siteId, markerId, payload).pipe(
          map(comment => Deserialize(comment, Comment)),
          map(comment => MarkerActions.addMarkerCommentSuccess({ comment })),
          catchError(error => of(MarkerActions.addMarkerCommentFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions,
              private api: ApiService,
              private translocoService: TranslocoService) {}

}
