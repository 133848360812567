import {
  autoserialize,
  autoserializeAs,
  deserialize, deserializeAs,
} from 'cerialize';


export class ElevationBatchData {
  @autoserialize batch!: string;
  @autoserialize altitude!: number;
  @deserialize position!: Array<number>;
  @autoserializeAs('date_acquired') dateAcquired!: string;
}

export class Elevation {
  kind: string = 'elevation';
  @autoserialize id!: string;
  @autoserialize name!: string;
  @autoserialize author!: string;
  @autoserialize point!: Array<number>;
  @autoserializeAs(ElevationBatchData) data!: Array<ElevationBatchData>;
  @deserializeAs('rotation_matrix') rotationMatrix!: Array<number>;
  @deserialize center!: Array<number>;
  @deserializeAs('camera_direction') cameraDirection!: Array<number>;
  @deserialize length!: number;
  @deserializeAs('is_shared') isShared!: boolean;
  show: boolean;

  constructor() {
    this.show = true;
  }

  get lastPosition(): Array<number> {
    return this.data[0].position;
  }
}
