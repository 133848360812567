import {
  Component,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Site } from '../../../../models/site';
import * as Cesium from 'cesium';
import {
  setCameraConfiguration,
  setCameraMode,
} from '../../../../features/cesium/cesium.actions';
import { first } from 'rxjs';
import {
  selectCameraConfiguration,
  selectCameraMode,
  selectMiniMapCameraConfiguration
} from '../../../../features/cesium/cesium.selectors';
import { CameraConfiguration } from '../../../../features/cesium/cesium.reducer';
import { CameraMode } from '../../../../enums/camera';
import { SiteTools } from '../../../../tools/site.tools';


@Component({
  selector: 'view-mode-selector',
  templateUrl: './view-mode-selector.component.html',
  styleUrls: ['./view-mode-selector.component.scss'],
})
export class ViewModeSelectorComponent {

  site: Site | null | undefined;
  height: number = 0;
  cameraMode: CameraMode = CameraMode.MODE_3D;
  private initDone: Boolean = false;
  private cameraMiniMapConfiguration: CameraConfiguration | undefined;

  constructor(private store: Store) {
    this.initHandleSite();
    this.initHandleCamera();
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.site = site;
          this.height = (this.site.height / 2.0) + this.site.altitude;
        }
      });
  }

  private initHandleCamera(): void {
    this.store.select(selectCameraConfiguration)
      .pipe(takeUntilDestroyed())
      .subscribe( (cameraConfiguration: any) => {
        if(this.cameraMode == CameraMode.MODE_2DV) {
          const position = Cesium.Cartesian3.fromElements(
            cameraConfiguration.position.x,
            cameraConfiguration.position.y,
            cameraConfiguration.position.z
          )
          const carto = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
          this.height = carto.height;
        }
      });
    this.store.select(selectMiniMapCameraConfiguration)
      .pipe(takeUntilDestroyed())
      .subscribe( (cameraConfiguration: any) => {
        this.cameraMiniMapConfiguration = cameraConfiguration;
      });

    // Handle camera mode change outside of this component (eg: from a detection)
    this.store.select(selectCameraMode)
      .pipe(takeUntilDestroyed())
      .subscribe( (mode: any) => {
        this.cameraMode = mode;
      });
  }

  onModeChanged(mode: any): void {
    if (!this.site) return;
    if (mode == this.cameraMode) return;
    switch (mode) {
      case CameraMode.MODE_2DH:{
        const position = Cesium.Cartesian3.fromDegrees(
          this.longitude,
          this.latitude,
          this.site.altitude + 500
        );
        const orientation = {
          heading: Cesium.Math.toRadians(0),
          pitch: Cesium.Math.toRadians(-90),
          roll: Cesium.Math.toRadians(0)
        }
        const cameraConfiguration:CameraConfiguration = {position, orientation};
        this.store.dispatch(setCameraMode({cameraMode: mode}));
        this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
        break;
      }
      case CameraMode.MODE_2DV: {
        this.store.select(selectMiniMapCameraConfiguration)
          .pipe(first())
          .subscribe( (miniMapCameraConfiguration: any) => {
            const position = Cesium.Cartesian3.fromDegrees(
              this.longitude,
              this.latitude,
              this.height
            );
            const orientation = (!this.initDone || !miniMapCameraConfiguration) ? {
              heading: Cesium.Math.toRadians(0),
              pitch: Cesium.Math.toRadians(0),
              roll: Cesium.Math.toRadians(0),
            } : miniMapCameraConfiguration.orientation;
            const cameraConfiguration:CameraConfiguration = {position, orientation};
            this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
            // Volontary update mode AFTER set CameraConfiguration
            this.store.dispatch(setCameraMode({cameraMode: mode}));
          });

        break;
      }
      default: {
        let position = Cesium.Cartesian3.fromDegrees(
          this.longitude,
          this.latitude,
          10
        );
        const offset = new Cesium.Cartesian3(1000, 0, 0);
        position = Cesium.Cartesian3.add(position, offset, position)
        const orientation = {
          heading: Cesium.Math.toRadians(0),
          pitch: Cesium.Math.toRadians(-45),
          roll: Cesium.Math.toRadians(0)
        }
        const cameraConfiguration:CameraConfiguration = {position, orientation};
        this.store.dispatch(setCameraMode({cameraMode: mode}));
        this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
        break;
      }
    }
    this.cameraMode = mode;
    this.initDone = true;
  }

  private get longitude(): number {
    if (this.initDone && this.cameraMiniMapConfiguration) {
      const position = Cesium.Cartesian3.fromElements(
        this.cameraMiniMapConfiguration.position.x,
        this.cameraMiniMapConfiguration.position.y,
        this.cameraMiniMapConfiguration.position.z
      )
      const carto = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
      return Cesium.Math.toDegrees(carto.longitude);
    }
    else if (this.site){
      return SiteTools.longitude(this.site);
    }
    return 0;
  }

  private get latitude(): number {
    if (this.initDone && this.cameraMiniMapConfiguration) {
      const position = Cesium.Cartesian3.fromElements(
        this.cameraMiniMapConfiguration.position.x,
        this.cameraMiniMapConfiguration.position.y,
        this.cameraMiniMapConfiguration.position.z
      )
      const carto = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
      return Cesium.Math.toDegrees(carto.latitude);
    }
    else if (this.site){
      return SiteTools.latitude(this.site);
    }
    return 0;
  }
}
