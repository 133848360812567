import {
  APP_INITIALIZER, ErrorHandler,
  NgModule
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HttpClientModule } from '@angular/common/http';
import { initializeKeycloak } from './init/keycloak-init.factory';
import {
  BrowserAnimationsModule,
  provideAnimations
} from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import * as fromUser from './features/user/user.reducer';
import * as fromOrganization from './features/organization/organization.reducer';
import * as fromSector from './features/sector/sector.reducer';
import * as fromSite from './features/site/site.reducer';
import * as fromBatch from './features/batch/batch.reducer';
import * as fromDetection from './features/detection/detection.reducer';
import * as fromCesium from './features/cesium/cesium.reducer';
import * as fromCut from './features/cut/cut.reducer';
import * as fromMarker from './features/marker/marker.reducer';
import * as fromMeasure from './features/measure/measure.reducer';
import * as fromVolume from './features/volume/volume.reducer';
import * as fromConfig from './features/config/config.reducer';
import * as fromTilesetConfig from './features/tileset-config/tileset-config.reducer';
import * as fromElevation from './features/elevation/elevation.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './features/user/user.effects';
import { OrganizationEffects } from './features/organization/organization.effects';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SharedModule } from './shared/shared.module';
import { SectorEffects } from './features/sector/sector.effects';
import { SiteEffects } from './features/site/site.effects';
import { ApiService } from './services/api.service';
import { BatchEffects } from './features/batch/batch.effects';
import { DetectionEffects } from './features/detection/detection.effects';
import { VolumeEffects } from './features/volume/volume.effects';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MeasureEffects } from './features/measure/measure.effects';
import { CutEffects } from './features/cut/cut.effects';
import { MarkerEffects } from './features/marker/marker.effects';
import { SiteModule } from './modules/site/site.module';
import { WorldModule } from './modules/world/world.module';
import { ReportEffects } from './features/report/report.effects';
import { TranslocoRootModule } from './transloco-root.module';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import {
  TilesetConfigEffects
} from './features/tileset-config/tileset-config.effects';
import { ElevationEffects } from './features/elevation/elevation.effects';

export const reducers: ActionReducerMap<any> = {
  user: fromUser.reducer,
  organizations: fromOrganization.reducer,
  sectors: fromSector.reducer,
  sites: fromSite.reducer,
  batchs: fromBatch.reducer,
  detections: fromDetection.reducer,
  cesium: fromCesium.reducer,
  cuts: fromCut.reducer,
  markers: fromMarker.reducer,
  measures: fromMeasure.reducer,
  volumes: fromVolume.reducer,
  config: fromConfig.reducer,
  tilesetConfigs: fromTilesetConfig.reducer,
  elevations: fromElevation.reducer
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    EffectsModule.forRoot([
      UserEffects,
      OrganizationEffects,
      SectorEffects,
      SiteEffects,
      BatchEffects,
      DetectionEffects,
      VolumeEffects,
      MeasureEffects,
      CutEffects,
      MarkerEffects,
      ReportEffects,
      TilesetConfigEffects,
      ElevationEffects
    ]),
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50}) : [],
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatSidenavModule,
    SharedModule,
    WorldModule,
    SiteModule,
    TranslocoRootModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [ApiService, KeycloakService]
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'} },
    Title,
    provideAnimations()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
