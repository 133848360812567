<mat-card class="container" @inOutAnimation>
  <mat-card-content>
    <tileset-style-form></tileset-style-form>
    <canvas #canvas></canvas>
    <div class="info" *ngIf="labelMin">
      <div>{{labelMin}}&nbsp;m</div>
      <div>{{labelMax}}&nbsp;m</div>
    </div>
  </mat-card-content>
</mat-card>
