export enum DataSourceType {
  PIN= 'pin',
  SITE = 'site',
  DETECTION = 'detection',
  VOLUME = 'volume',
  MEASURE = 'measure',
  CUT = 'cut',
  MARKER = 'marker',
  ELEVATION = 'elevation'
}
