import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  animate,
  animateChild,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  selectConfigTilesetColorRampMax,
  selectConfigTilesetColorRampMin,
  selectConfigTilesetStyleColorConditions
} from '../../../features/config/config.selectors';
import {
  selectCurrentTilesetConfig
} from '../../../features/tileset-config/tileset-config.selectors';
import { TilesetConfig } from '../../../models/tileset-config';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'tileset-diff-color-ramp',
  templateUrl: './tileset-diff-color-ramp.component.html',
  styleUrls: ['./tileset-diff-color-ramp.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ bottom: '-400px'}),
        query('@*', animateChild(), { optional: true }),
        animate('350ms ease-out', style({ bottom: '0'})),
      ]),
      transition(':leave', [
        style({ bottom: '25px'}),
        animate('300ms ease-in', style({ bottom: '-400px'}))
      ]),
    ]),
  ],
})
export class TilesetDiffColorRampComponent implements AfterViewInit {

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement> | undefined;
  labelMin: string | undefined;
  labelMax: string | undefined;
  private colors: Array<string> = [];


  constructor(private store: Store) {
    this.initHandleConfig();
    this.initHandleTilesetConfig();
  }

  private initHandleTilesetConfig(): void {
    this.store.select(selectCurrentTilesetConfig)
      .pipe(takeUntilDestroyed())
      .subscribe((tilesetConfig: TilesetConfig | null | undefined) => {
        this.labelMin = (tilesetConfig) ? "0" : undefined;
        this.labelMax = (tilesetConfig) ? tilesetConfig.maxDistance.toFixed(1) : undefined;
      });
    this.store.select(selectConfigTilesetColorRampMin)
      .pipe(takeUntilDestroyed())
      .subscribe((value: number) => {
        this.labelMin = value.toString();
      });
    this.store.select(selectConfigTilesetColorRampMax)
      .pipe(takeUntilDestroyed())
      .subscribe((value: number) => {
        this.labelMax = value.toString();
      });
  }

  private initHandleConfig() {
    this.store.select(selectConfigTilesetStyleColorConditions)
      .pipe(takeUntilDestroyed())
      .subscribe(((conditions: Array<any> | undefined) => {
        const alteredConditions = (conditions) ? cloneDeep(conditions) : [];
        this.colors = (conditions) ? alteredConditions?.map((c:any) => {
          const params = c[1];
          return "hsl("+ String(params[0]) + "turn,"+ String(params[1]*100) +"%, "+ String(params[2]*100) + "%)";
        }) : [];
        this.draw(this.colors);
      }));
  }

  private generateGradient(colors: Array<string>): void {
    if(this.canvas) {
      const ctx = this.canvas.nativeElement.getContext('2d');
      if (ctx && colors.length) {
        const range = colors.length;
        const d = (height:number) => height / range;
        const width = this.canvas.nativeElement.width;
        const height = this.canvas.nativeElement.height;
        const grd = ctx.createLinearGradient(0, 0, width, 0);
        colors.forEach((color: string, index:number) => {
          grd.addColorStop(d(index), color);
        });
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, width, height);
      }
    }
  }

  private clearCanvas(): void {
    if(this.canvas) {
      const ctx = this.canvas.nativeElement.getContext('2d')
      if (ctx) {
        const width = this.canvas.nativeElement.width;
        const height = this.canvas.nativeElement.height;
        ctx.clearRect(0, 0, width, height);
      }
    }
  }

  private draw(colors: any): void {
    this.clearCanvas();
    this.generateGradient(colors);

  }

  ngAfterViewInit(): void {
    this.draw(this.colors);
  }
}
