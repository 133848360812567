import {
  Component,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Site } from '../../../../models/site';
import { InspectionModule } from '../../../../enums/inspection-module';
import { ViewRepresentation } from '../../../../enums/representation';
import {
  setConfigViewRepresentation
} from '../../../../features/config/config.actions';
import { Batch } from '../../../../models/batch';
import { selectAllBatchs } from '../../../../features/batch/batch.selectors';
import {
  selectVolumeSummary
} from '../../../../features/volume/volume.selectors';
import {
  selectDetectionSummary
} from '../../../../features/detection/detection.selectors';


@Component({
  selector: 'view-representation-selector',
  templateUrl: './view-representation-selector.component.html',
  styleUrls: ['./view-representation-selector.component.scss'],
})
export class ViewRepresentationSelectorComponent {

  site: Site | null | undefined;
  batchs: Array<Batch> = [];
  volumeSummary: any;
  detectionSummary: any;
  viewRepresentation = ViewRepresentation;
  inspectionModule = InspectionModule;
  representationControl = new FormControl(ViewRepresentation.DEFAULT);

  constructor(private store: Store) {
    this.initHandleSite();
    this.initHandleBatch();
    this.initHandleVolumeSummary();
    this.initHandleDetectionSummary();
  }

  private initHandleDetectionSummary(): void {
    this.store.select(selectDetectionSummary)
      .pipe(takeUntilDestroyed())
      .subscribe((summary) => {
        this.detectionSummary = summary;
      });
  }

  private initHandleVolumeSummary(): void {
    this.store.select(selectVolumeSummary)
      .pipe(takeUntilDestroyed())
      .subscribe((summary) => {
        this.volumeSummary = summary;
      });
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.site = site;
        }
      });
  }

  private initHandleBatch(): void {
    this.store.select(selectAllBatchs)
      .pipe(takeUntilDestroyed())
      .subscribe((batchs: Array<Batch> | null | undefined) => {
          this.batchs = (batchs) ? batchs : [];
      });
  }

  onChangeRepresentation(event: any): void {
    this.store.dispatch(setConfigViewRepresentation({value: event.value}));
  }

}
