import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';

import { MatBadgeModule } from '@angular/material/badge';
import { SiteListComponent } from './site-list/site-list.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  SiteVolumeSummaryComponent
} from './site-summary/site-volume-summary/site-volume-summary.component';
import {
  SiteDetectionSummaryComponent
} from './site-summary/site-detection-summary/site-detection-summary.component';
import {
  SiteDetailsHeaderComponent
} from './site-details-header/site-details-header.component';
import {
  SiteDetailsContentComponent
} from './site-details-content/site-details-content.component';
import {
  SiteListHeaderComponent
} from './site-list-header/site-list-header.component';
import { SiteCardComponent } from './site-card/site-card.component';
import { SiteFiltersComponent } from './site-filters/site-filters.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    SiteListComponent,
    SiteListHeaderComponent,
    SiteCardComponent,
    SiteDetailsContentComponent,
    SiteDetailsHeaderComponent,
    SiteDetectionSummaryComponent,
    SiteVolumeSummaryComponent,
    SiteFiltersComponent
  ],
  exports: [
    SiteListComponent,
    SiteCardComponent,
    SiteListHeaderComponent,
    SiteDetailsContentComponent,
    SiteDetailsHeaderComponent,
    SiteDetectionSummaryComponent,
    SiteVolumeSummaryComponent,
    SiteFiltersComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatTooltipModule,
        MatDialogModule,
        MatExpansionModule,
        MatInputModule,
        MatButtonToggleModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatCardModule,
        MatBadgeModule,
        ReactiveFormsModule,
        MatTreeModule,
        MatChipsModule,
        MatMenuModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatSliderModule,
        MatTabsModule,
        TranslocoModule
    ]
})
export class SiteModule {}
