import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as PipelineActions from './pipeline.actions';
import { PerreadPipeline } from '../../models/perread_pipeline';
import { Deserialize } from 'cerialize';


@Injectable()
export class PipelineEffects {

  startPerreadPipeline$ = createEffect(() => {
    return this.actions$.pipe(ofType(PipelineActions.startPerreadPipeline),
      concatMap(({organizationId, siteId, payload}) =>
      this.api.startPerreadPipeline$(organizationId, siteId, payload).pipe(
        map(pipeline => Deserialize(pipeline, PerreadPipeline)),
        map(pipeline => PipelineActions.startPerreadPipelineSuccess({ pipeline })),
        catchError(error => of(PipelineActions.startPerreadPipelineFailure({ error }))))
    ));
  });

  retrivePerreadPipeline$ = createEffect(() => {
    return this.actions$.pipe(ofType(PipelineActions.retrievePerreadPipeline),
      concatMap(({organizationId, siteId, referenceBatchId, comparedBatchId}) =>
        this.api.retrievePerreadPipeline$(organizationId, siteId, referenceBatchId, comparedBatchId).pipe(
          map(pipeline => Deserialize(pipeline, PerreadPipeline)),
          map(pipeline => PipelineActions.retrievePerreadPipelineSuccess({ pipeline })),
          catchError(error => of(PipelineActions.retrievePerreadPipelineFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions, private api: ApiService) {}

}
