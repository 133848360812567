import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { getUser, getUserSuccess } from './features/user/user.actions';
import { selectUser } from './features/user/user.selectors';
import { combineLatest, first, Observable } from 'rxjs';
import { User } from './models/user';
import {
  loadOrganizations,
  loadOrganizationsFailure,
  loadOrganizationsSuccess,
  selectOrganization
} from './features/organization/organization.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  selectAllOrganizations,
  selectCurrentOrganization, selectOrganizationTotal
} from './features/organization/organization.selectors';
import { Title } from '@angular/platform-browser';
import { Organization } from './models/organization';
import { Router } from '@angular/router';
import { loadSectors } from './features/sector/sector.actions';
import { Actions, ofType } from '@ngrx/effects';
import {
  activateSiteFailure, activateSiteSuccess,
  deactivateSite,
  deactivateSiteFailure,
  deactivateSiteSuccess,
  loadSites,
  selectSite
} from './features/site/site.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  UserConfigurationErrorDialog
} from './shared/dialogs/user-configuration-error-dialog/user-configuration-error.dialog';
import {
  selectConfigColorRampMax,
  selectConfigColorRampMin,
  selectConfigColorRampNegativeColors,
  selectConfigColorRampPositiveColors,
  selectConfigSideMode,
  selectConfigDisplaySitesFilters
} from './features/config/config.selectors';
import { ConfigSideMode } from './enums/config';
import { setConfigSideMode } from './features/config/config.actions';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  selectCurrentSite
} from './features/site/site.selectors';
import { Site } from './models/site';
import { SiteService } from './services/site.service';
import { ReportDialog } from './shared/dialogs/report-dialog/report.dialog';
import {
  selectAllVolumes,
  selectVolumeEmergencyTemplate
} from './features/volume/volume.selectors';
import { selectAllMeasures } from './features/measure/measure.selectors';
import { selectAllCuts } from './features/cut/cut.selectors';
import { selectAllMarkers } from './features/marker/marker.selectors';
import {
  InspectionReportSettings,
  InspectionReportVolume,
  InspectionReport, InspectionReportDetection,
} from './models/inspection-report';
import { selectAllDetections } from './features/detection/detection.selectors';
import {
  generateReport, generateReportFailure,
  generateReportSuccess
} from './features/report/report.actions';
import { ConfirmDialog } from './shared/dialogs/confirm-dialog/confirm.dialog';
import {
  OrganizationDialog
} from './shared/dialogs/organization-dialog/organization.dialog';
import { TranslocoService } from '@jsverse/transloco';
import {
  SiteDeactivationDialog
} from './shared/dialogs/site-deactivation-dialog/site-deactivation.dialog';
import { EmergencyTemplateLabel } from './models/emergency-template';
import { EmergencyLevel } from './enums/emergency';
import { Color } from './enums/color';
import { selectAllElevations } from './features/elevation/elevation.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('sideExpandedCollpased', [
      state('expanded', style({
        width: '300px'
      })),
      state('collapsed', style({
        width: '56px'
      })),
      transition('expanded => collapsed', [
        animate('150ms ease-out'),
      ]),
      transition('collapsed => expanded', [
        animate('150ms ease-out'),
      ])
    ]),
  ],
})
export class AppComponent {

  private organization: Organization | undefined | null;
  sideMode: ConfigSideMode = ConfigSideMode.MODE_EXPANDED;
  sideModeEnum = ConfigSideMode;
  displaySitesFilters: boolean = false;

  constructor(private store: Store,
              private siteService: SiteService,
              private keycloakService: KeycloakService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              private router: Router,
              private actions: Actions,
              public title: Title,
              private translocoService: TranslocoService) {
    this.translocoService.setActiveLang(navigator.language.slice(0,2));
    this.initHandleConfig();
    this.initHandleOrganization();
    this.initHandleUser();
    this.initHandleReport();
    this.initHandleSite();
    this.store.dispatch(getUser());
  }

  private initHandleReport(): void {
    this.actions.pipe(ofType(generateReportSuccess), takeUntilDestroyed())
      .subscribe(data => {
        this.toastr.success(this.translocoService.translate('generate_report_success', {mail: data.email}));
      });

    this.actions.pipe(ofType(generateReportFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.toastr.error(this.translocoService.translate('generate_report_failure'));
      });
  }

  private initHandleConfig(): void {
    this.store.select(selectConfigSideMode)
      .pipe(takeUntilDestroyed())
      .subscribe((sideMode: ConfigSideMode) => {
        this.sideMode = sideMode;
      });
    this.store.select(selectConfigDisplaySitesFilters)
      .pipe(takeUntilDestroyed())
      .subscribe((displaySitesFilters: boolean) => {
        this.displaySitesFilters = displaySitesFilters;
      });
  }

  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe( result => {
        if (result) {
          this.store.dispatch(loadOrganizations());
        }
      });

    this.actions.pipe(ofType(getUserSuccess), first())
      .subscribe(result => {
        const user = result['user'];
        this.translocoService.setActiveLang(user.languageCode);
      });
  }

  private initHandleOrganization(): void {
    this.store.select(selectCurrentOrganization)
      .pipe(takeUntilDestroyed())
      .subscribe( (organization: Organization | null | undefined) => {
        if (organization) {
          if (!this.organization || (this.organization && this.organization.id != organization.id)) {
            this.organization = organization;
            this.title.setTitle(this.organization.name);
            this.store.dispatch(loadSectors({organizationId: this.organization.id}));
            this.store.dispatch(loadSites({organizationId: this.organization.id}));
          }
        }
      });

    this.actions.pipe(ofType(loadOrganizationsSuccess), first())
      .subscribe(result => {
        const organizations = result['organizations']
        if (organizations.length == 0){
          this.displayUserConfigurationErrorDialog();
        }
      });

    this.actions.pipe(ofType(loadOrganizationsFailure), first())
      .subscribe(result => {
        this.displayUserConfigurationErrorDialog();
      });

    this.store.select(selectAllOrganizations)
      .pipe(takeUntilDestroyed())
      .subscribe( result => {
        if (result.length && !this.organization) {
          const organization = result[0];
          this.store.dispatch(selectOrganization({organizationId: organization.id}));
        }
      });
  }

  private initHandleSite(): void {
    this.actions.pipe(ofType(activateSiteSuccess), takeUntilDestroyed())
      .subscribe(data => {
        const site: any = data['update']['changes'];
        this.toastr.success(this.translocoService.translate('activate_site_success', {site: site['name']}));
      });
    this.actions.pipe(ofType(activateSiteFailure), takeUntilDestroyed())
      .subscribe(data => {
        this.toastr.error(this.translocoService.translate('activate_site_failure'));
      });

    this.actions.pipe(ofType(deactivateSiteSuccess), takeUntilDestroyed())
      .subscribe(data => {
        const site: any = data['update']['changes'];
        this.toastr.success(this.translocoService.translate('deactivate_site_success', {site: site['name']}));
        this.store.dispatch(selectSite({siteId: undefined}));
      });
    this.actions.pipe(ofType(deactivateSiteFailure), takeUntilDestroyed())
      .subscribe(data => {
        this.toastr.error(this.translocoService.translate('deactivate_site_failure'));
      });
  }

  private displayUserConfigurationErrorDialog(): void {
    const dialogRef = this.dialog.open(UserConfigurationErrorDialog, {
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.keycloakService.logout().catch(err => this.toastr.error(err));
    });
  }

  private generateReports(formats: Array<string>): void {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectConfigColorRampPositiveColors),
      this.store.select(selectConfigColorRampNegativeColors),
      this.store.select(selectConfigColorRampMin),
      this.store.select(selectConfigColorRampMax),
      this.store.select(selectVolumeEmergencyTemplate),
      this.store.select(selectAllMeasures),
      this.store.select(selectAllCuts),
      this.store.select(selectAllMarkers),
      this.store.select(selectAllElevations),
      this.store.select(selectAllVolumes),
      this.store.select(selectAllDetections),
    ]).pipe(first())
      .subscribe(results => {
        const site: Site | null | undefined = results[0];
        if(site) {
          const emergencyColors = ['#ffffff'];
          if (results[5]) {
            const unique = [...new Map(results[5].labels.map(item =>
              [item['level'], item])).values()];
            unique
              .sort((a, b) => a.level > b.level ? -1 : a.level < b.level ? 1 : 0)
              .forEach((label: EmergencyTemplateLabel) => {
                if (label.level == EmergencyLevel.HIGH) emergencyColors.push(Color.EMERGENCY_HIGH);
                if (label.level == EmergencyLevel.MEDIUM_HIGH) emergencyColors.push(Color.EMERGENCY_MEDIUM_HIGH);
                if (label.level == EmergencyLevel.MEDIUM_LOW) emergencyColors.push(Color.EMERGENCY_MEDIUM_LOW);
                if (label.level == EmergencyLevel.LOW) emergencyColors.push(Color.EMERGENCY_LOW);
              });
          }

          const settings = InspectionReportSettings.with(
            formats,
            results[1],
            results[2],
            results[3],
            results[4],
            emergencyColors);

          const report = new InspectionReport();
          report.settings = settings;
          report.measures = results[6];
          report.cuts = results[7];
          report.markers = results[8];
          report.elevations = results[9];
          report.volumes = InspectionReportVolume.fromVolumes(results[10]);
          report.detections = InspectionReportDetection.fromDetections(results[11]);

          this.store.dispatch(generateReport({
            organizationId: site.organization,
            siteId: site.id,
            batchId: site.lastBatch,
            payload: report
          }));
        }
    });
  }

  // setSideMode(mode: any): void {
  //   this.store.dispatch(setConfigSideMode({sideMode: mode}));
  // }

  toggleSideMode(): void {
    const mode = (this.sideMode === this.sideModeEnum.MODE_EXPANDED) ? this.sideModeEnum.MODE_COLLAPSED : this.sideModeEnum.MODE_EXPANDED
    this.store.dispatch(setConfigSideMode({sideMode: mode}));
  }

  displayOrganizations(): void {
    const dialogRef = this.dialog.open(OrganizationDialog, {
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(organizationId => {
      if (organizationId) {
        this.store.dispatch(selectOrganization({organizationId}));
      }
    });
  }

  logout(): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      disableClose: false
    });
    dialogRef.componentInstance.icon = 'logout';
    dialogRef.componentInstance.title = this.translocoService.translate('logout');
    dialogRef.componentInstance.message = this.translocoService.translate('logout_message');
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.keycloakService.logout().catch(err => this.toastr.error(err));
      }
    });
  }

  report(): void {
    const dialogRef = this.dialog.open(ReportDialog, {
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(formats => {
      if (formats) {
        this.generateReports(formats);
      }
    });
  }

  onClickDeactivateSite(site: Site) {
    const deactivateSiteDialogRef = this.dialog.open(SiteDeactivationDialog);
    deactivateSiteDialogRef.componentInstance.siteName = site.name;
    deactivateSiteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.dispatch(deactivateSite({
          organizationId: site.organization,
          siteId: site.id
        }));
      }
    });
  }

  close(site: any): void {
    this.siteService.flyTo(site);
    this.store.dispatch(selectSite({siteId: undefined}));
  }

  get user$(): Observable<User | undefined> {
    return this.store.select(selectUser);
  }

  get organizationCount$(): Observable<number> {
    return this.store.select(selectOrganizationTotal);
  }

  get organization$(): Observable<Organization | undefined | null> {
    return this.store.select(selectCurrentOrganization);
  }

  get site$(): Observable<Site | undefined | null> {
    return this.store.select(selectCurrentSite);
  }

  // keep this for later
  // product_permission(user:User, product: string): boolean {
  //   const requiredRoles = [`${this.organization?.slug}_${product}`];
  //   return requiredRoles.every((role) => user.roles.includes(role));
  // }
}
