import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectConfigSitesFilters } from '../../features/config/config.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Site } from '../../models/site';
import {
  SiteFilterDetectionKeys,
  SiteFilterKeys, SiteFilterVolumeKeys
} from '../../enums/site-filter';

@Pipe({
  name: 'filters'
})
export class FiltersPipe implements PipeTransform {

  private filters: any | undefined;

  constructor(private store: Store) {
    this.initHandleSiteFilters();
  }

  private initHandleSiteFilters(): void {
    this.store.select(selectConfigSitesFilters)
      .pipe(takeUntilDestroyed())
      .subscribe((filters: any | undefined) => {
        this.filters = filters;
      });
  }

  transform(items: Array<Site>): Array<Site> {
    if (!items) return [];
    if (!this.filters) return items;
    let filtererItems = this.sectorFilter(items);
    filtererItems = this.emergencyFilter(filtererItems);
    filtererItems = this.detectionFilter(filtererItems);
    filtererItems = this.volumeFilter(filtererItems);
    return filtererItems;
  }


  private sectorFilter(items: Array<Site>): Array<Site> {
    if(!this.filters[SiteFilterKeys.SECTORS] || this.filters[SiteFilterKeys.SECTORS].length == 0) return items;
    return items.filter((item: Site) => {
      return this.filters[SiteFilterKeys.SECTORS].includes(item.sector);
    });
  }

  private emergencyFilter(items: Array<Site>): Array<Site> {
    if(!this.filters[SiteFilterKeys.EMERGENCIES] || this.filters[SiteFilterKeys.EMERGENCIES].length == 0) return items;
    return items.filter((item: Site) => {
      return this.filters[SiteFilterKeys.EMERGENCIES].includes(String(item.emergencyLabel));
    });
  }

  private detectionFilter(items: Array<Site>): Array<Site> {
    if(!this.filters[SiteFilterKeys.DETECTIONS]) return items;
    return items.filter((item: Site) => {
      if(!item.detectionSummary && !item.volumeSummary) return false;
      if(!item.detectionSummary && item.volumeSummary) return true;

      let settings = this.filters[SiteFilterKeys.DETECTIONS][SiteFilterDetectionKeys.TOTAL];
      const isInTotalRange = settings.start <= item.detectionSummary.detectionCount && item.detectionSummary.detectionCount <= settings.end;
      settings = this.filters[SiteFilterKeys.DETECTIONS][SiteFilterDetectionKeys.HIGH_EMERGENCY_LEVEL];
      const isInHighEmergencyLevelRange = settings.start <= item.detectionSummary.emergencyCount && item.detectionSummary.emergencyCount <= settings.end;
      settings = this.filters[SiteFilterKeys.DETECTIONS][SiteFilterDetectionKeys.DENSITY_PEAK];
      const densityPeak: number = item.detectionSummary.densityPeak;
      const isInDensityPeakRange = settings.start <= densityPeak && densityPeak <= settings.end;

      return isInTotalRange && isInHighEmergencyLevelRange && isInDensityPeakRange;
    });
  }

  private volumeFilter(items: Array<Site>): Array<Site> {
    if(!this.filters[SiteFilterKeys.VOLUMES]) return items;
    return items.filter((item: Site) => {
      if(!item.detectionSummary && !item.volumeSummary) return false;
      if(item.detectionSummary && !item.volumeSummary) return true;

      let settings = this.filters[SiteFilterKeys.VOLUMES][SiteFilterVolumeKeys.POSITIVE_VOLUME_MOVED];
      const isInPositiveVolumeMovedRange = settings.start <= item.volumeSummary.positiveVolumeMoved && item.volumeSummary.positiveVolumeMoved <= settings.end;
      settings = this.filters[SiteFilterKeys.VOLUMES][SiteFilterVolumeKeys.NEGATIVE_VOLUME_MOVED];
      const isInNegativeVolumeMovedRange = settings.start <= item.volumeSummary.negativeVolumeMoved && item.volumeSummary.negativeVolumeMoved <= settings.end;
      settings = this.filters[SiteFilterKeys.VOLUMES][SiteFilterVolumeKeys.HIGH_EMERGENCY_LEVEL];
      const isInHighEmergencyLevelRange = settings.start <= item.volumeSummary.emergencyCount && item.volumeSummary.emergencyCount <= settings.end;
      settings = this.filters[SiteFilterKeys.VOLUMES][SiteFilterVolumeKeys.DENSITY_PEAK];
      const densityPeak: number = item.volumeSummary.densityPeak;
      const isInDensityPeakRange = settings.start <= densityPeak && densityPeak <= settings.end;

      return isInPositiveVolumeMovedRange && isInNegativeVolumeMovedRange && isInHighEmergencyLevelRange && isInDensityPeakRange;
    });
  }
}
