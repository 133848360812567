export class TilesetConfig {
  id!: string;
  name!: string;
  url!: string;
  canApplyStyle: boolean;
  maxDistance: number;

  constructor(id: string, name: string, url: string, canApplyStyle: boolean, maxDistance: number) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.canApplyStyle = canApplyStyle;
    this.maxDistance = maxDistance;
  }
}
