import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as VolumeActions from './volume.actions';
import { Volume } from '../../models/volume';
import { VolumeColorMode } from '../../enums/volume';
import { EmergencyTemplate } from '../../models/emergency-template';
import { clearVolumeSummary } from './volume.actions';


export interface State extends EntityState<Volume> {
  selectedId: string | undefined;
  colorMode: VolumeColorMode;
  emergencyTemplate: EmergencyTemplate | undefined;
  summary: any;
}

export const adapter: EntityAdapter<Volume> = createEntityAdapter<Volume>();

export const initialState: State = adapter.getInitialState({
  selectedId: undefined,
  colorMode: VolumeColorMode.COLOR_MODE_VOLUME_MOVED,
  emergencyTemplate: undefined,
  summary: undefined,
});

const volumeReducer = createReducer(
  initialState,
  on(VolumeActions.loadVolumes, (state) => {
    return state;
  }),
  on(VolumeActions.loadVolumesSuccess, (state, { volumes }) => {
    return adapter.setAll(volumes, state);
  }),
  on(VolumeActions.loadVolumesFailure, (state, { error }) => {
    return state;
  }),
  on(VolumeActions.clearAllVolumes, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(VolumeActions.setVolumeColorMode, (state, {colorMode}) => {
    return { ...state, colorMode: colorMode };
  }),
  on(VolumeActions.selectVolume, (state, {id}) => {
    return { ...state, selectedId: id };
  }),
  on(VolumeActions.loadVolumeEmergencyTemplate, (state) => {
    return state;
  }),
  on(VolumeActions.loadVolumeEmergencyTemplateSuccess, (state, { template }) => {
    return { ...state, emergencyTemplate: template };
  }),
  on(VolumeActions.loadVolumeEmergencyTemplateFailure, (state, { error }) => {
    return state;
  }),
  on(VolumeActions.clearVolumeEmergencyTemplate, (state) => {
    return { ...state, emergencyTemplate: undefined };
  }),
  on(VolumeActions.addVolumeEmergency, (state) => {
    return state;
  }),
  on(VolumeActions.addVolumeEmergencySuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(VolumeActions.addVolumeEmergencyFailure, (state, { error }) => {
    return state;
  }),
  on(VolumeActions.loadVolumeSummary, (state) => {
    return state;
  }),
  on(VolumeActions.loadVolumeSummarySuccess, (state, { summary }) => {
    return { ...state, summary: summary };
  }),
  on(VolumeActions.loadVolumeSummaryFailure, (state, { error }) => {
    return state;
  }),
  on(VolumeActions.clearVolumeSummary, (state ) => {
    return { ...state, summary: undefined };
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return volumeReducer(state, action);
}

export const getSelectedVolumeId = (state: State) => state.selectedId;
export const getVolumeEmergencyTemplate = (state: State) => state.emergencyTemplate;
export const getVolumeSummary = (state: State) => state.summary;
