import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromElevation from './elevation.reducer';


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromElevation.adapter.getSelectors();

export const selectElevationState = createFeatureSelector<fromElevation.State>('elevations');

export const selectElevationIds = createSelector(
  selectElevationState,
  selectIds
);
export const selectElevationEntities = createSelector(
  selectElevationState,
  selectEntities
);
export const selectAllElevations = createSelector(
  selectElevationState,
  selectAll
);
export const selectElevationTotal = createSelector(
  selectElevationState,
  selectTotal
);


export const selectCurrentElevationId = createSelector(
  selectElevationState,
  fromElevation.getSelectedElevationId
);

export const selectCurrentElevation = createSelector(
  selectElevationEntities,
  selectCurrentElevationId,
  (entities, id) => (id) ? entities[id] : null
);
