import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest } from 'rxjs';
import { Chart } from 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js/dist/types';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { Color } from '../../../enums/color';
import { DatePipe } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { selectUser } from '../../../features/user/user.selectors';
import { Elevation, ElevationBatchData } from '../../../models/elevation';
import {
  selectCurrentElevation
} from '../../../features/elevation/elevation.selectors';



@Component({
  selector: 'elevation-chart',
  template: '<canvas #canvas></canvas><div id="custom-tooltip" style="position: absolute; display: none; background: #333; color: #fff; padding: 10px; border-radius: 5px; pointer-events: none; font-size: 14px;"></div>',
  styles: ['canvas { width: 100%; height: 100%}']
})
export class ElevationChartComponent implements AfterViewInit {

  @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement> | undefined;
  elevation: Elevation | null | undefined;
  private chart: any;
  private config: any;


  constructor(private store: Store, private translocoService: TranslocoService) {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectCurrentElevation),
      this.store.select(selectUser)
    ]).pipe(takeUntilDestroyed())
      .subscribe(contents => {
        const site:any = contents[0];
        this.elevation = contents[1];
        const user = contents[2];

        if (site && this.elevation && user) {
          const dateFormat = user.languageCode == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
          const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());

          const olderElevationBatchData = this.elevation.data[this.elevation.data.length-1];
          const latestElevationBatchData = this.elevation.data[0];

          const olderDate = new Date(olderElevationBatchData.dateAcquired);
          const latestDate = new Date(latestElevationBatchData.dateAcquired);
          const differenceInTime = latestDate.getTime() - olderDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
          const nbDays = Math.floor(Math.abs(differenceInDays));
          const color = Color.ANALOGOUS_1;

          const dataset: any = {
            data: this.elevation.data.map((ebd:ElevationBatchData) => {
              const ebdDate = new Date(ebd.dateAcquired);
              const differenceInTime = ebdDate.getTime() - olderDate.getTime();
              const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
              const nbDays = Math.floor(Math.abs(differenceInDays));
              return [nbDays, ebd.altitude];
            }),
            borderColor: color,
            backgroundColor: color,
            radius: 5,
          };

          const data: ChartData = {
            labels: this.elevation.data.map((ebd:ElevationBatchData) => {
              return datePipe.transform(new Date(ebd.dateAcquired), dateFormat);
            }),
            datasets: [
              dataset
            ]
          }

          const options: ChartOptions = {
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false, // Désactive le tooltip natif
                external: function (context) {

                  const customTooltip = document.getElementById('custom-tooltip');
                  if (customTooltip) {
                    // Fonction pour gérer le tooltip personnalisé
                    const tooltipModel = context.tooltip;

                    if (tooltipModel.opacity === 0) {
                      customTooltip.style.display = 'none';
                      return;
                    }

                    // Construire le contenu du tooltip
                    if (tooltipModel.dataPoints) {
                      const dataPoint:any = tooltipModel.dataPoints[0];
                      if (context.chart.data.labels) {
                        const label = context.chart.data.labels[dataPoint.dataIndex];
                        const value = dataPoint.formattedValue; // Valeur brute
                        customTooltip.innerHTML = `
                            <strong>${label}</strong><br>
                            ${value} m
                        `;
                      }
                    }

                    // Positionner et afficher le tooltip
                    customTooltip.style.left = tooltipModel.caretX + 'px';
                    customTooltip.style.top = tooltipModel.caretY + 'px';
                    customTooltip.style.display = 'block';
                  }
                },
              },
            },
            animation: {
              duration: 0
            },
            scales: {
              x:{
                type: 'linear',
                beginAtZero: true,
                ticks: {
                  display: false,
                },
              },
              y:{
                type: 'linear',
                beginAtZero: true,
              },
            }
          }
          this.config = {
            type:'line',
            data,
            options,
          }
          this.draw();
        }
      });
  }

  private draw(): void {
    if (this.canvasRef?.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
      if (ctx) {
        if (this.chart) this.chart.destroy();
        this.chart = new Chart(ctx, this.config);
      }
    }
  }

  ngAfterViewInit(): void {
    this.draw();
  }

  resize(): void {
    this.draw();
  }

  canvasToImage(filename: string): void {
    if (this.canvasRef?.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      let canvasUrl = canvas.toDataURL("image/png");
      const createEl = document.createElement('a');
      createEl.href = canvasUrl;
      createEl.download = filename.replace(/ /g,"_");
      createEl.click();
      createEl.remove();
    }
  }

}
