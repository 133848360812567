import { createAction, props } from '@ngrx/store';
import { Volume } from '../../models/volume';
import { VolumeColorMode } from '../../enums/volume';
import { EmergencyTemplate } from '../../models/emergency-template';
import { Update } from '@ngrx/entity';


export const loadVolumes = createAction('[Volume/API] Load Volumes ', props<{ organizationId: string, siteId: string, batchId: string, threshold: number, step: number, comparedToBatchId: string | undefined }>());
export const loadVolumesSuccess = createAction('[Volume/API] Load Volumes success', props<{ volumes: Volume[] }>());
export const loadVolumesFailure = createAction('[Volume/API] Load Volumes failure', props<{ error: any }>());
export const clearAllVolumes = createAction('[Volume] Clear All Volumes');
export const setVolumeColorMode = createAction('[Volume] Set Volume Color Mode', props<{ colorMode: VolumeColorMode }>());
export const selectVolume = createAction('[Volume] Select Volume', props<{ id: string | undefined}>());
export const loadVolumeEmergencyTemplate = createAction('[Volume/API] Load Volume Emergency Template ', props<{  organizationId: string, siteId: string }>());
export const loadVolumeEmergencyTemplateSuccess = createAction('[Volume/API] Load Volume Emergency Template success', props<{ template: EmergencyTemplate }>());
export const loadVolumeEmergencyTemplateFailure = createAction('[Volume/API] Load Volume Emergency Template failure', props<{ error: any }>());
export const clearVolumeEmergencyTemplate = createAction('[Volume] Clear Volume Emergency Template');
export const addVolumeEmergency = createAction('[Volume/API] Add Volume Emergency ', props<{ organizationId: string, siteId: string, batchId: string, volumeId: string, payload: any }>());
export const addVolumeEmergencySuccess = createAction('[Volume/API] Load Volume Emergency success', props<{ update: Update<any> }>());
export const addVolumeEmergencyFailure = createAction('[Volume/API] Load Volume Emergency failure', props<{ error: any }>());

export const loadVolumeSummary = createAction('[Volume/API] Load Volume Summary ', props<{ organizationId: string, siteId: string, batchId: string, comparedToBatchId: string }>());
export const loadVolumeSummarySuccess = createAction('[Volume/API] Load Volumes Summary success', props<{ summary: any }>());
export const loadVolumeSummaryFailure = createAction('[Volume/API] Load Volumes Summary failure', props<{ error: any }>());
export const clearVolumeSummary = createAction('[Volume] Clear Volume Summary');
