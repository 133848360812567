<mat-card class="container" *ngIf="marker && (currentUser$ | async) as currentUser">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{marker.name}}</mat-card-title>
      <mat-card-subtitle>{{marker.center | wgs84}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              (click)="onClickClose()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <div>
      <div *ngIf="currentUser.name != marker.author" class="author">{{'created_by_f' | transloco }} {{marker.author}}</div>
      <marker-history-channel [markerId]="marker.id"></marker-history-channel>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="left-actions">
      <button *ngIf="currentUser.name == marker.author"
              mat-icon-button
              matTooltip="{{'delete' | transloco}}"
              (click)="onClickDelete(marker)">delete
      </button>
      <div *ngIf="currentUser.name == marker.author" class="vertical-separator"></div>
      <button mat-icon-button
              matTooltip="{{'export' | transloco}}"
              class="material-symbols-outlined"
              [matMenuTriggerFor]="menu">ios_share
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onClickExportPicture(marker)">{{'export_picture' | transloco}}</button>
      </mat-menu>
      <button mat-icon-button
              matTooltip="{{'move_the_camera_on_the_maker' | transloco}}"
              class="material-symbols-outlined"
              (click)="onClickFlyTo(marker)">location_on
      </button>
      <button *ngIf="currentUser.name == marker.author"
              mat-icon-button
              matTooltip="{{'rename' | transloco}}"
              (click)="onClickRename(marker)"><mat-icon>edit</mat-icon></button>

      <button *ngIf="!marker.isShared && currentUser.name == marker.author"
              mat-icon-button
              matTooltip="{{'share' | transloco}}"
              (click)="onClickToggleShare(marker)">
        <mat-icon>share</mat-icon>
      </button>
      <button *ngIf="marker.isShared && currentUser.name == marker.author"
              mat-icon-button
              matTooltip="{{'share_off' | transloco}}"
              (click)="onClickToggleShare(marker)">
        <mat-icon>share_off</mat-icon>
      </button>
    </div>
  </mat-card-actions>
</mat-card>
