import {
  Component, Input,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  selectConfigSideMode, selectConfigSitesFilters
} from '../../../features/config/config.selectors';
import { ConfigSideMode } from '../../../enums/config';
import { selectUser } from '../../../features/user/user.selectors';
import {
  loadOrganizations
} from '../../../features/organization/organization.actions';
import {
  setConfigSideMode,
  setConfigDisplaySitesFilters
} from '../../../features/config/config.actions';
import { Observable } from 'rxjs';
import { User } from '../../../models/user';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'site-list-header',
  templateUrl: './site-list-header.component.html',
  styleUrls: ['./site-list-header.component.scss']
})
export class SiteListHeaderComponent {

  sideMode: ConfigSideMode = ConfigSideMode.MODE_EXPANDED;
  sideModeEnum = ConfigSideMode
  sitesFiltersDefined: boolean = false;

  constructor(private store: Store,
              private keycloakService: KeycloakService,
              private toastr: ToastrService,) {
    this.initHandleConfig();
    this.initHandleUser();
  }

  private initHandleConfig(): void {
    this.store.select(selectConfigSideMode)
      .pipe(takeUntilDestroyed())
      .subscribe((sideMode: ConfigSideMode) => {
        this.sideMode = sideMode;
      });
    this.store.select(selectConfigSitesFilters)
      .pipe(takeUntilDestroyed())
      .subscribe((sitesFilters: any) => {
        this.sitesFiltersDefined = (sitesFilters != undefined);
      });
  }

  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe( result => {
        if (result) {
          this.store.dispatch(loadOrganizations());
        }
      });
  }

  setSideMode(mode: any): void {
    this.store.dispatch(setConfigSideMode({sideMode: mode}));
  }

  openFilters(): void {
    this.store.dispatch(setConfigDisplaySitesFilters({display: true}))
  }

  logout(): void {
    this.keycloakService.logout()
      .catch(err => this.toastr.error(err));
  }

  get user$(): Observable<User | undefined> {
    return this.store.select(selectUser);
  }

}
