import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ElevationActions from './elevation.actions';
import { Deserialize } from 'cerialize';
import { Elevation } from '../../models/elevation';
import { ApiService } from '../../services/api.service';
import { TranslocoService } from '@jsverse/transloco';
import { Comment } from '../../models/comment';


@Injectable()
export class ElevationEffects {

  loadElevations$ = createEffect(() => {
    return this.actions$.pipe(ofType(ElevationActions.loadElevations),
      concatMap(({organizationId, siteId}) =>
        this.api.elevations$(organizationId, siteId).pipe(
          map(elevations => elevations.map((elevation: any) => {
            const obj: Elevation = Deserialize(elevation, Elevation);
            if (!obj.name) {
              obj.name = this.translocoService.translate('elevation') + " " + obj.id.slice(0,4).toUpperCase();
            }
            return obj;
          })),
          map(elevations => ElevationActions.loadElevationsSuccess({ elevations })),
          catchError(error => of(ElevationActions.loadElevationsFailure({ error }))))
      ));
  });

  addElevation$ = createEffect(() => {
    return this.actions$.pipe(ofType(ElevationActions.addElevation),
      concatMap(({organizationId, siteId, point}) =>
      this.api.addElevation$(organizationId, siteId, point).pipe(
        map(elevation => {
          const obj: Elevation = Deserialize(elevation, Elevation);
          obj.name = this.translocoService.translate('elevation') + " " + obj.id.slice(0,4).toUpperCase();
          return obj;
        }),
        map(elevation => ElevationActions.addElevationSuccess({ elevation })),
        catchError(error => of(ElevationActions.addElevationFailure({ error }))))
    ));
  });

  deleteElevation$ = createEffect(() => {
    return this.actions$.pipe(ofType(ElevationActions.deleteElevation),
      concatMap(({organizationId, siteId, elevationId}) =>
        this.api.deleteElevation$(organizationId, siteId, elevationId).pipe(
          map(elevation =>  ElevationActions.deleteElevationSuccess({ id: elevationId })),
          catchError(error => of(ElevationActions.deleteElevationFailure({ error }))))
      ));
  });

  updateElevation$ = createEffect(() => {
    return this.actions$.pipe(ofType(ElevationActions.updateElevation),
      concatMap(({organizationId, siteId, elevationId, payload}) =>
        this.api.updateElevation$(organizationId, siteId, elevationId, payload).pipe(
          map(elevation => Deserialize(elevation, Elevation)),
          map(elevation => {
            return {id: elevation.id, changes: elevation};
          }),
          map(update => ElevationActions.updateElevationSuccess({ update })),
          catchError(error => of(ElevationActions.updateElevationFailure({ error }))))
      ));
  });

  loadElevationComments$ = createEffect(() => {
    return this.actions$.pipe(ofType(ElevationActions.loadElevationComments),
      concatMap(({organizationId, siteId, elevationId}) =>
        this.api.elevationComments$(organizationId, siteId, elevationId).pipe(
          map(comments => comments.map((comment: any) => Deserialize(comment, Comment))),
          map(comments => ElevationActions.loadElevationCommentsSuccess({ comments })),
          catchError(error => of(ElevationActions.loadElevationCommentsFailure({ error }))))
      ));
  });

  addElevationComment$ = createEffect(() => {
    return this.actions$.pipe(ofType(ElevationActions.addElevationComment),
      concatMap(({organizationId, siteId, elevationId, payload}) =>
        this.api.addElevationComment$(organizationId, siteId, elevationId, payload).pipe(
          map(comment => Deserialize(comment, Comment)),
          map(comment => ElevationActions.addElevationCommentSuccess({ comment })),
          catchError(error => of(ElevationActions.addElevationCommentFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions,
              private api: ApiService,
              private translocoService: TranslocoService) {}

}
