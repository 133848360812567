<mat-card *ngIf="cut"
          style="cursor: pointer;"
          [style.border]=border
          (click)="onClickEdit(cut)">

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{cut.name}}</mat-card-title>
  </mat-card-header>
  <div *ngIf="cut.isShared && (currentUser$ | async) as currentUser" class="shared-item-indicators">
    <mat-icon *ngIf="currentUser.name == cut.author">person</mat-icon>
    <mat-icon>share</mat-icon>
  </div>
</mat-card>



