import { createAction, props } from '@ngrx/store';
import { PerreadPipeline } from '../../models/perread_pipeline';


export const startPerreadPipeline = createAction('[Report/API] Start PERREAD Pipeline', props<{ organizationId: string, siteId: string, payload: any}>());
export const startPerreadPipelineSuccess = createAction('[Report/API] Start PERREAD Pipeline success', props<{ pipeline: PerreadPipeline }>());
export const startPerreadPipelineFailure = createAction('[Report/API] Start PERREAD Pipeline failure', props<{ error: any }>());

export const retrievePerreadPipeline = createAction('[Report/API] Retrieve PERREAD Pipeline', props<{ organizationId: string, siteId: string, referenceBatchId: string, comparedBatchId: string}>());
export const retrievePerreadPipelineSuccess = createAction('[Report/API] Retrieve PERREAD Pipeline success', props<{ pipeline: PerreadPipeline }>());
export const retrievePerreadPipelineFailure = createAction('[Report/API] Retrieve PERREAD Pipeline failure', props<{ error: any }>());
