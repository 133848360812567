import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as MarkerActions from './marker.actions';
import { Marker } from '../../models/marker';


export interface State extends EntityState<Marker> {
  selectedId: string | undefined;
}

export const adapter: EntityAdapter<Marker> = createEntityAdapter<Marker>();

export const initialState: State = adapter.getInitialState({selectedId: undefined});

const markerReducer = createReducer(
  initialState,
  on(MarkerActions.loadMarkers, (state) => {
    return state;
  }),
  on(MarkerActions.loadMarkersSuccess, (state, { markers }) => {
    return adapter.setAll(markers, state);
  }),
  on(MarkerActions.loadMarkersFailure, (state, { error }) => {
    return state;
  }),
  on(MarkerActions.addMarker, (state) => {
    return state;
  }),
  on(MarkerActions.addMarkerSuccess, (state, { marker }) => {
    return adapter.setOne(marker, state);
  }),
  on(MarkerActions.addMarkerFailure, (state, { error }) => {
    return state;
  }),
  on(MarkerActions.updateMarker, (state) => {
    return state;
  }),
  on(MarkerActions.updateMarkerSuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(MarkerActions.updateMarkerFailure, (state) => {
    return state;
  }),
  on(MarkerActions.deleteMarker, (state) => {
    return state;
  }),
  on(MarkerActions.deleteMarkerSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(MarkerActions.deleteMarkerFailure, (state, { error }) => {
    return state;
  }),
  on(MarkerActions.clearAllMarkers, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(MarkerActions.selectMarker, (state, {id}) => {
    return { ...state, selectedId: id };
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return markerReducer(state, action);
}

export const getSelectedMarkerId = (state: State) => state.selectedId;
