import { autoserialize, autoserializeAs } from 'cerialize';

export class DifferentialTileset {
  @autoserialize id!: string;
  @autoserializeAs('batch_compared_id') batchComparedId!: string;
  @autoserializeAs('batch_compared_date') batchComparedDate!: string;
  @autoserializeAs('batch_reference_id') batchReferenceId!: string;
  @autoserializeAs('batch_reference_date') batchReferenceDate!: string;
  @autoserializeAs('max_distance') maxDistance!: number;
}
