import {
  Component, EventEmitter, Output,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { Site } from '../../../models/site';
import { InspectionModule } from '../../../enums/inspection-module';

import { MatDialog } from '@angular/material/dialog';
import { User } from '../../../models/user';
import { selectUser } from '../../../features/user/user.selectors';
import {
  selectVolumeSummary
} from '../../../features/volume/volume.selectors';
import {
  selectDetectionSummary
} from '../../../features/detection/detection.selectors';
import { Actions, ofType } from '@ngrx/effects';
import {
  addElevationSuccess,
  selectElevation
} from '../../../features/elevation/elevation.actions';
import {
  clearVolumeSummary,
  loadVolumeSummary, loadVolumeSummaryFailure,
  loadVolumeSummarySuccess
} from '../../../features/volume/volume.actions';
// import {
//   selectVolumeSummary
// } from '../../../features/volume/volume.selectors';




@Component({
  selector: 'site-details-header',
  templateUrl: './site-details-header.component.html',
  styleUrls: ['./site-details-header.component.scss']
})
export class SiteDetailsHeaderComponent {

  site: Site | null | undefined;
  user: User | null | undefined;
  inspectionModule = InspectionModule;
  volumeSummary: any;
  isLoadingVolumeSummary: boolean = true;
  detectionSummary: any;
  isLoadingDetectionSummary: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter()

  constructor(private store: Store, private actions: Actions, private dialog: MatDialog) {
    this.initHandleSite();
    this.initHandleUser();
    this.initHandleVolumeSummary();
    this.initHandleDetectionSummary();
  }

  private initHandleDetectionSummary(): void {
    this.store.select(selectDetectionSummary)
      .pipe(takeUntilDestroyed())
      .subscribe((summary) => {
        this.detectionSummary = summary;
      });
  }

  private initHandleVolumeSummary(): void {
    this.store.select(selectVolumeSummary)
      .pipe(takeUntilDestroyed())
      .subscribe((summary) => {
        this.volumeSummary = summary;
      });
    this.actions.pipe(ofType(loadVolumeSummary), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingVolumeSummary = true;
      });
    this.actions.pipe(ofType(loadVolumeSummarySuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingVolumeSummary = false;
      });
    this.actions.pipe(ofType(loadVolumeSummaryFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingVolumeSummary = false;
        this.store.dispatch(clearVolumeSummary());
      });
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.site = site;
        }
      });
  }

  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      });
  }

  onClickDeselectSite() {
    this.close.next(this.site);
  }

  onClickLaunchPerreadPipeline() {

  }

  get dateFormat() {
    if(this.user) {
      return (this.user.languageCode == 'fr') ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    }
    return 'MM/dd/yyyy';
  }

}
