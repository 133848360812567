export enum DrawingMode {
  MEASURE_LINE = 1,
  MEASURE_POLYGON,
  CUT_LINE,
  CUT_MULTI_LINE,
  MARKER_POINT,
  NEW_DETECTION,
  IMAGES_AT_POINT,
  ELEVATION
}
