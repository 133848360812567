<div *ngIf="site" class="container">
  <div class="main">
    <button mat-icon-button
            matTooltip="{{'back_to_sites' | transloco}}"
            class="material-symbols-outlined"
            (click)="onClickDeselectSite()">
      arrow_back
    </button>
    <div>
      <mat-card-title>
        <span style="position: relative; font-weight: 600">
          {{site.name}}
          <div class="emergency-badge" [style.background-color]="site.textColor"></div>
        </span>
      </mat-card-title>
      <mat-card-subtitle>
        {{site | sector}}
      </mat-card-subtitle>
    </div>
  </div>
  <div class="acquisition-date">
    {{'latest_acquisition' | transloco}}: {{site.lastBatchDate | date:dateFormat}}
  </div>
  <div class="acquisition-date">
    <compared-batch-selector [site]=site></compared-batch-selector>
  </div>
  <div class="draw">
    <site-details-draw-toolbar [site]=site></site-details-draw-toolbar>
  </div>
  <div class=modules>
    <div *ngIf="(site | module: [inspectionModule.ARCAD]) && site.detectionSummary"
         class=module>
      <site-detection-summary [site]="site"></site-detection-summary>
      <density-chart [data]="site.detectionSummary.densities"
                     [label]="'density_chart_detections' | transloco"></density-chart>
    </div>
    <div *ngIf="(site | module: [inspectionModule.PERREAD])"
         class=module>
<!--      <mat-spinner *ngIf="isLoadingVolumeSummary" strokeWidth="10"></mat-spinner>-->
      <div *ngIf="volumeSummary && !isLoadingVolumeSummary">
        <site-volume-summary [volumeSummary]="volumeSummary"></site-volume-summary>
        <density-chart [data]="volumeSummary.densities"
                       [label]="'density_chart_volumes' | transloco"></density-chart>
      </div>
      <div *ngIf="!volumeSummary && !isLoadingVolumeSummary" class="volume-summary-missing">
        <p>{{ 'missing_perread_data' | transloco }}</p>
        <button mat-icon-button
                matTooltip="{{'start_calculation' | transloco}}"
                (click)="onClickLaunchPerreadPipeline()">rocket_launch
        </button>
      </div>
    </div>
  </div>
</div>
