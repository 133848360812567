import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as DetectionActions from './detection.actions';
import { Detection } from '../../models/detection';
import { EmergencyTemplate } from '../../models/emergency-template';
import { DetectionTemplate } from '../../models/detection-template';


export interface State extends EntityState<Detection> {
  selectedId: string | undefined;
  emergencyTemplate: EmergencyTemplate | undefined;
  detectionTemplate: DetectionTemplate | undefined;
  summary: any;
}

export const adapter: EntityAdapter<Detection> = createEntityAdapter<Detection>();

export const initialState: State = adapter.getInitialState({
  selectedId: undefined,
  emergencyTemplate: undefined,
  detectionTemplate: undefined,
  summary: undefined,
});

const detectionReducer = createReducer(
  initialState,
  on(DetectionActions.loadDetections, (state) => {
    return state;
  }),
  on(DetectionActions.loadDetectionsSuccess, (state, { detections }) => {
    return adapter.setAll(detections, state);
  }),
  on(DetectionActions.loadDetectionsFailure, (state, { error }) => {
    return state;
  }),
  on(DetectionActions.clearAllDetections, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(DetectionActions.selectDetection, (state, {id}) => {
    return { ...state, selectedId: id };
  }),
  on(DetectionActions.loadDetectionEmergencyTemplate, (state) => {
    return state;
  }),
  on(DetectionActions.loadDetectionEmergencyTemplateSuccess, (state, { template }) => {
    return { ...state, emergencyTemplate: template };
  }),
  on(DetectionActions.loadDetectionEmergencyTemplateFailure, (state, { error }) => {
    return state;
  }),
  on(DetectionActions.clearDetectionEmergencyTemplate, (state) => {
    return { ...state, emergencyTemplate: undefined };
  }),
  on(DetectionActions.addDetectionEmergency, (state) => {
    return state;
  }),
  on(DetectionActions.addDetectionEmergencySuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(DetectionActions.addDetectionEmergencyFailure, (state, { error }) => {
    return state;
  }),
  on(DetectionActions.updateDetection, (state) => {
    return state;
  }),
  on(DetectionActions.updateDetectionSuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(DetectionActions.updateDetectionFailure, (state, { error }) => {
    return state;
  }),
  on(DetectionActions.deleteDetection, (state) => {
    return state;
  }),
  on(DetectionActions.deleteDetectionSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(DetectionActions.deleteDetectionFailure, (state, { error }) => {
    return state;
  }),
  on(DetectionActions.addDetection, (state) => {
    return state;
  }),
  on(DetectionActions.addDetectionSuccess, (state, { detection }) => {
    return adapter.addOne(detection, state);
  }),
  on(DetectionActions.addDetectionFailure, (state, { error }) => {
    return state;
  }),
  on(DetectionActions.loadDetectionTemplate, (state) => {
    return state;
  }),
  on(DetectionActions.loadDetectionTemplateSuccess, (state, { template }) => {
    return { ...state, detectionTemplate: template };
  }),
  on(DetectionActions.loadDetectionTemplateFailure, (state, { error }) => {
    return state;
  }),
  on(DetectionActions.clearDetectionTemplate, (state) => {
    return { ...state, detectionTemplate: undefined };
  }),
  on(DetectionActions.loadDetectionSummary, (state) => {
    return state;
  }),
  on(DetectionActions.loadDetectionSummarySuccess, (state, { summary }) => {
    return { ...state, summary: summary };
  }),
  on(DetectionActions.loadDetectionSummaryFailure, (state, { error }) => {
    return state;
  }),
  on(DetectionActions.clearDetectionSummary, (state ) => {
    return { ...state, summary: undefined };
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return detectionReducer(state, action);
}

export const getSelectedDetectionId = (state: State) => state.selectedId;
export const getDetectionEmergencyTemplate = (state: State) => state.emergencyTemplate;
export const getDetectionTemplate = (state: State) => state.detectionTemplate;
export const getDetectionSummary = (state: State) => state.summary;
