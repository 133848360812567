import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as TilesetConfigActions from './tileset-config.actions';
import { Deserialize } from 'cerialize';
import { DifferentialTileset } from '../../models/differential-tileset';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';
import { TilesetConfig } from '../../models/tileset-config';
import { DatePipe } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { SiteTools } from '../../tools/site.tools';


@Injectable()
export class TilesetConfigEffects {

  loadTilesetConfigs$ = createEffect(() => {
    const activeLang = this.translocoService.getActiveLang();
    const datePipe: DatePipe = new DatePipe(activeLang);
    const dateFormat = (activeLang.startsWith('fr')) ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    return this.actions$.pipe(ofType(TilesetConfigActions.loadTilesetConfigs),
      concatMap(({site}) =>
      this.api.differentialTilesets$(site.organization, site.id).pipe(
        map(differentialTilesets => differentialTilesets.map((dt: any) => {
          const obj: DifferentialTileset = Deserialize(dt, DifferentialTileset);
          const tilesUrl = environment.tilesUrl + "/" + obj.id + "/tileset.json";
          const tilesetConfig = new TilesetConfig(obj.id, obj.batchComparedId, tilesUrl, true, obj.maxDistance);
          return tilesetConfig;
        })),
        map(tilesetConfigs => {
          const tilesUrl = SiteTools.tilesUrl(site);
          if (tilesUrl) {
            const tilesetConfig = new TilesetConfig("0", "default", tilesUrl, false, 0);
            tilesetConfigs.unshift(tilesetConfig);
          }
          return tilesetConfigs;
        }),
        map(tilesetConfigs => TilesetConfigActions.loadTilesetConfigsSuccess({ tilesetConfigs })),
        catchError(error => of(TilesetConfigActions.loadTilesetConfigsFailure({ error }))))
    ));
  });

  addTilesetConfig$ = createEffect(() => {
    const activeLang = this.translocoService.getActiveLang();
    const datePipe: DatePipe = new DatePipe(activeLang);
    const dateFormat = (activeLang.startsWith('fr')) ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    return this.actions$.pipe(ofType(TilesetConfigActions.addTilesetConfig),
      concatMap(({organizationId, siteId, batch_compared, batch_reference}) =>
        this.api.addDifferentialTileset$(organizationId, siteId, batch_compared, batch_reference).pipe(
          map(differentialTileset => {
            const obj: DifferentialTileset = Deserialize(differentialTileset, DifferentialTileset);
            const tilesUrl = environment.tilesUrl + "/" + obj.id + "/tileset.json";
            const tilesetConfig = new TilesetConfig(obj.id, obj.batchComparedId, tilesUrl, true, obj.maxDistance);
            return tilesetConfig;
          }),
          map(tilesetConfig => TilesetConfigActions.addTilesetConfigSuccess({ tilesetConfig })),
          catchError(error => of(TilesetConfigActions.addTilesetConfigFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions, private api: ApiService, private translocoService: TranslocoService) {}

}
