<div class="container">
  <div cesium [handleKeyboard]="mapHandleKeyboard"></div>

  <volume-color-ramp *ngIf="selectedSite
  && selectedViewRepresentation == viewRepresentation.VOLUMES
  && (selectedSite | module: [inspectionModule.PERREAD])">
  </volume-color-ramp>

  <tileset-diff-color-ramp *ngIf="selectedSite
  && selectedViewRepresentation == viewRepresentation.DIFFERENTIAL
  && (selectedTilesetConfigId$ | async)">
  </tileset-diff-color-ramp>

  <compute-loader></compute-loader>

  <div *ngIf="selectedSite" class="view-configuration">
    <view-configuration></view-configuration>
  </div>

  <detection-details></detection-details>
  <volume-details></volume-details>
  <measure-details></measure-details>
  <cut-details></cut-details>
  <marker-details></marker-details>
  <elevation-details></elevation-details>

  <div *ngIf="selectedSite" class="entities">
    <draw-entities-toolbar [site]="selectedSite"></draw-entities-toolbar>
<!--    <world-search></world-search>-->
  </div>

</div>
