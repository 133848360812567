import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Detection } from '../models/detection';
import { Volume } from '../models/volume';
import { Site } from '../models/site';
import { selectSite } from '../features/site/site.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  selectDrawingMode,
  selectHeightReference
} from '../features/cesium/cesium.selectors';
import { DrawingMode } from '../enums/drawing-mode';
import { Measure } from '../models/measure';
import { Marker } from '../models/marker';
import { Cut } from '../models/cut';
import { selectVolumeColorMode } from '../features/volume/volume.selectors';
import { selectMeasure } from '../features/measure/measure.actions';
import { selectVolume } from '../features/volume/volume.actions';
import { selectMarker } from '../features/marker/marker.actions';
import { selectDetection } from '../features/detection/detection.actions';
import { selectCut } from '../features/cut/cut.actions';
import { VolumeColorMode } from '../enums/volume';
import { Color } from '../enums/color';
import { selectCurrentMeasureId } from '../features/measure/measure.selectors';
import { first } from 'rxjs';
import { selectCurrentMarkerId } from '../features/marker/marker.selectors';
import { selectCurrentCutId } from '../features/cut/cut.selectors';
import { SiteStatus } from '../enums/site';
import { selectElevation } from '../features/elevation/elevation.actions';
import { Elevation } from '../models/elevation';
import {
  selectCurrentElevationId
} from '../features/elevation/elevation.selectors';

@Injectable({
  providedIn: 'root'
})
export class CesiumEntityService {

  private drawingMode: DrawingMode | undefined;
  private volumeColorMode: VolumeColorMode = VolumeColorMode.COLOR_MODE_VOLUME_MOVED;
  private heightReference: any;
  private clampToGround: boolean = true;

  constructor(private store: Store) {
    this.initHandleDrawingMode();
    this.initHandleHeightReference();
    this.initHandleVolumeColorMode();
  }

  private initHandleVolumeColorMode() {
    this.store.select(selectVolumeColorMode)
      .pipe(takeUntilDestroyed())
      .subscribe((colorMode: any) => {
        this.volumeColorMode = colorMode;
      });
  }

  private initHandleDrawingMode(): void {
    this.store.select(selectDrawingMode)
      .pipe(takeUntilDestroyed())
      .subscribe((mode: DrawingMode | undefined) => {
        this.drawingMode = mode;
      });
  }

  private initHandleHeightReference(): void {
    this.store.select(selectHeightReference)
      .pipe(takeUntilDestroyed())
      .subscribe((reference: any) => {
        this.heightReference = reference;
        switch (this.heightReference){
          case Cesium.HeightReference.CLAMP_TO_TERRAIN: case Cesium.HeightReference.CLAMP_TO_GROUND: case Cesium.HeightReference.CLAMP_TO_3D_TILE: {
            this.clampToGround = true;
            break;
          }
          default: {
            this.clampToGround = false;
            break;
          }
        }
      });
  }

  displayEntity(entity: any): void {
    if(this.drawingMode) return;
    let item = undefined;
    switch (entity.kind) {
      case 'site': {
        const site = entity.site;
        if (site.status == SiteStatus.UPDATING || site.status == SiteStatus.RUNNING) {
          this.store.dispatch(selectSite({siteId: site.id}));
        }
        break;
      }
      case 'detection': {
        item = entity;
        this.store.dispatch(selectDetection({id: undefined}));
        this.store.dispatch(selectDetection({id: item.id}));
        break;
      }
      case 'volume': {
        item = entity.volume;
        this.store.dispatch(selectVolume({id: item.id}));
        break;
      }
      case 'cut': {
        item = entity.cut;
        this.store.dispatch(selectCut({id: item.id}));
        break;
      }
      case 'measure': {
        item = entity.measure;
        this.store.dispatch(selectMeasure({id: item.id}));
        break;
      }
      case 'marker': {
        item = entity.marker;
        this.store.dispatch(selectMarker({id: item.id}));
        break;
      }
      case 'elevation': {
        item = entity.elevation;
        this.store.dispatch(selectElevation({id: item.id}));
        break;
      }
      default:
        break
    }

    // if (item) {
    //   const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(item.center, item.cameraDirection, item.length);
    //   if (!cameraConfiguration) return;
    //   this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    //   this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
    // }
  }

  createDrawPointEntity(dataSource: any,  drawingMode: DrawingMode, position: any, height: number = 0): any {
    const color = Cesium.Color.fromCssColorString(Color.ACCENT);
    switch (drawingMode) {
      case DrawingMode.MARKER_POINT:
        const sizeMarker = 0.5;
        return dataSource.entities.add({
          position: position,
          ellipsoid: {
            radii: new Cesium.Cartesian3(sizeMarker, sizeMarker, sizeMarker),
            material: color.withAlpha(0.8),
          },
        })
      case DrawingMode.ELEVATION:
        const size = 0.1;
        const length = size*6;
        const cartographic = Cesium.Cartographic.fromCartesian(position);
        const translation = new Cesium.Cartesian3(0, 0, length / 2.0);
        const shiftedPosition = new Cesium.Cartesian3(cartographic.longitude, cartographic.latitude, cartographic.height);
        const positionWGS84 = Cesium.Cartesian3.add(shiftedPosition, translation, new Cesium.Cartesian3());
        const positionECEF = Cesium.Cartesian3.fromRadians(positionWGS84.x, positionWGS84.y, positionWGS84.z);
        return dataSource.entities.add({
          position: positionECEF,
          cylinder: {
            bottomRadius: 0,
            topRadius: size,
            length: length,
            material: color,
            depthFailMaterial: color,
          },
        })

      default:
        return dataSource.entities.add({
          position: position,
          point: {
            color,
            pixelSize: 5,
            clampToGround: this.clampToGround,
          }
        });
    }
  }


  drawShape(dataSource: any, drawingMode: DrawingMode, positionData: any, minimumHeights: any, maximumHeights: any): any {
    if (!drawingMode) return undefined;
    const color = Cesium.Color.fromCssColorString(Color.ACCENT);
    switch (drawingMode) {
      case DrawingMode.CUT_LINE:
        return dataSource.entities.add({
          wall: {
            positions: positionData,
            material: color.withAlpha(0.7),
            depthFailMaterial: color.withAlpha(0.7),
            minimumHeights,
            maximumHeights
          },
        });
      // case DrawingMode.NEW_DETECTION:
      //   const coordinates = Cesium.Rectangle.fromCartesianArray(positionData);
      //   return dataSource.entities.add({
      //     rectangle: {
      //       coordinates,
      //       material: color.withAlpha(0.7),
      //     },
      //   });
      default:
        const colorProperty = new Cesium.ColorMaterialProperty()
        colorProperty.color = new Cesium.CallbackProperty((time:any, result:any) => {
          return color;
        }, false);
        return dataSource.entities.add({
          polyline: {
            positions: positionData,
            classificationType: Cesium.ClassificationType.BOTH,
            material: colorProperty,
            depthFailMaterial: colorProperty,
            width: 2
          },
        });
    }
  }

  createSiteEntity(dataSource: any, site: Site): void {
    if(site.outline) {
      let hierarchy:Array<any> = [];
      for (const index in site.outline) {
        const coords = site.outline[index];
        hierarchy.push(Cesium.Cartesian3.fromArray(coords));
      }

      let color;
      if (site.status == SiteStatus.UPDATING || site.status == SiteStatus.RUNNING) {
        if (site.emergencyLevel) {
          const colors = [
            Cesium.Color.fromCssColorString(Color.EMERGENCY_HIGH).withAlpha(0.8),
            Cesium.Color.fromCssColorString(Color.EMERGENCY_MEDIUM_HIGH).withAlpha(0.8),
            Cesium.Color.fromCssColorString(Color.EMERGENCY_MEDIUM_LOW).withAlpha(0.8),
            Cesium.Color.fromCssColorString(Color.EMERGENCY_LOW).withAlpha(0.8)
          ];
          color = new Cesium.ColorMaterialProperty(
            colors[site.emergencyLevel-1]
          );
        }
        else {
          color = new Cesium.ColorMaterialProperty(
            Cesium.Color.fromCssColorString(Color.PRIMARY).withAlpha(0.8)
          );
        }
      }
      else {
        color = new Cesium.ColorMaterialProperty(
          Cesium.Color.fromCssColorString(Color.UNAVAILABLE).withAlpha(0.8)
        );
      }

      const entity: any = {
        site,
        kind: 'site',
        polygon: {
          hierarchy,
          material: color,
        },
      }
      dataSource.entities.add(entity);
    }
  }

  createCameraEntity(dataSource: any, target: Array<number>, camDirection: Array<number>, maxLength: number, coeff: number =1 ): void {
    const cameraCoords = target.map((val: number, index: number) => val + (camDirection[index] * coeff * maxLength));
    const origin = Cesium.Cartesian3.fromArray(cameraCoords);
    const position = Cesium.Cartesian3.fromArray(target);

    // Cylinder should point to 'target' from 'origin'
    const direction = Cesium.Cartesian3.subtract(position, origin, new Cesium.Cartesian3());
    Cesium.Cartesian3.normalize(direction, direction);

    const rotationMatrix = Cesium.Transforms.rotationMatrixFromPositionVelocity(origin, direction);
    const rot90 = Cesium.Matrix3.fromRotationY(Cesium.Math.toRadians(90));
    Cesium.Matrix3.multiply(rotationMatrix, rot90, rotationMatrix);

    dataSource.entities.add({
      position: origin,
      orientation: Cesium.Quaternion.fromRotationMatrix(
        rotationMatrix
      ),
      cylinder: {
        length: 1,
        topRadius: 0,
        bottomRadius: 1,
        material: Cesium.Color.LIGHTBLUE,
        depthFailMaterial: Cesium.Color.LIGHTBLUE
      }
    });

    // Visualize direction
    const directionRay = Cesium.Cartesian3.multiplyByScalar(direction, maxLength, new Cesium.Cartesian3());
    Cesium.Cartesian3.add(origin, directionRay, directionRay);

    dataSource.entities.add({
      polyline: {
        positions: [origin, directionRay],
        width: 1,
        material: Cesium.Color.WHITE,
        depthFailMaterial: Cesium.Color.WHITE
      }
    });
  }

  createVolumeEntity(dataSource: any, volume: Volume, displayCameraEntity: boolean = false): void {
    const hierarchy:number[] = [];
    volume.polygon.forEach((point: Array<number>) => {
      hierarchy.push(Cesium.Cartesian3.fromArray(point));
    });
    const color = new Cesium.ColorMaterialProperty(new Cesium.CallbackProperty(() => {
      if (this.volumeColorMode == VolumeColorMode.COLOR_MODE_VOLUME_MOVED) {
        const color = Cesium.Color.fromCssColorString(volume.hexColor);
        return color.withAlpha(0.7);
      }
      else {
        const color = Cesium.Color.fromCssColorString(volume.textColor);
        return color.withAlpha(0.7);
      }
    }, false));
    const entity: any = {
      volume,
      kind: 'volume',
      polygon: {
        hierarchy,
        classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
        material: color,
        depthFailMaterial: color
      }
    };
    dataSource.entities.add(entity);

    if (displayCameraEntity) {
      this.createCameraEntity(
        dataSource,
        volume.center,
        volume.cameraDirection,
        1);
    }
  }

  createMeasureEntity(dataSource: any, measure: Measure): void {
    const color = new Cesium.ColorMaterialProperty(new Cesium.CallbackProperty(() => {
      let selectedId = undefined;
      this.store.select(selectCurrentMeasureId)
        .pipe(first()).subscribe((result: any) =>selectedId = result);
      const selectedColor = (measure.id == selectedId) ? Color.TRIADIC_2 : Color.ANALOGOUS_1;
      const color = Cesium.Color.fromCssColorString(selectedColor);
      return color.withAlpha(0.7);
    }, false));

    let entity: any = {
      measure,
      kind: 'measure',
      show: measure.show,
      label: {
        text: measure.name
      },
    }
    if (measure.polygon) {
      const hierarchy:number[] = [];
      measure.areaVolume.projectedPolygon.forEach((point: Array<number>) => {
        hierarchy.push(Cesium.Cartesian3.fromArray(point));
      });
      Object.assign(entity, {
        polygon: {
          hierarchy,
          material: color,
          classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
        }
      });
    }
    else {
      const point1 = Cesium.Cartesian3.fromArray(measure.point1);
      const point2 = Cesium.Cartesian3.fromArray(measure.point2);
      Object.assign(entity, {
        polyline: {
          positions: [point1, point2],
          material: color,
          depthFailMaterial: color,
          width: 2
        }
      });
    }
    dataSource.entities.add(entity);
  }

  createMarkerEntity(dataSource: any, marker: Marker, displayCameraEntity: boolean = false): void {
    const color = new Cesium.ColorMaterialProperty(new Cesium.CallbackProperty(() => {
      let selectedId = undefined;
      this.store.select(selectCurrentMarkerId)
        .pipe(first()).subscribe((result: any) =>selectedId = result);
      const selectedColor = (marker.id == selectedId) ? Color.TRIADIC_2 : Color.TRIADIC_1;
      const color = Cesium.Color.fromCssColorString(selectedColor);
      return color.withAlpha(0.7);
    }, false));
    const size = 0.5;
    const entity: any = {
      marker,
      kind: 'marker',
      show: marker.show,
      position: Cesium.Cartesian3.fromArray(marker.point),
      ellipsoid: {
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(5.0, Number.MAX_VALUE),
        radii: new Cesium.Cartesian3(size, size, size),
        material: color,
        depthFailMaterial: color,
      },
    }
    dataSource.entities.add(entity);

    if (displayCameraEntity) {
      this.createCameraEntity(
        dataSource,
        marker.center,
        marker.cameraDirection,
        2);
    }
  }

  createCutEntity(dataSource: any, cut: Cut, alitude: number = 0, height: number = 10): void {
    const color = new Cesium.ColorMaterialProperty(new Cesium.CallbackProperty(() => {
      let selectedId = undefined;
      this.store.select(selectCurrentCutId)
          .pipe(first()).subscribe((result: any) => selectedId = result);
      const selectedColor = (cut.id == selectedId) ? Color.TRIADIC_2 : Color.TRIADIC_1;
      const color = Cesium.Color.fromCssColorString(selectedColor);
      return color.withAlpha(0.7);
    }, false));
    const cartographic1 = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromArray(cut.point1));
    const longitude1 = Cesium.Math.toDegrees(cartographic1.longitude);
    const latitude1 = Cesium.Math.toDegrees(cartographic1.latitude);
    const cartographic2 = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromArray(cut.point2));
    const longitude2 = Cesium.Math.toDegrees(cartographic2.longitude);
    const latitude2 = Cesium.Math.toDegrees(cartographic2.latitude);

    const positions = Cesium.Cartesian3.fromDegreesArrayHeights([
      longitude1,
      latitude1,
      alitude + height,
      longitude2,
      latitude2,
      alitude + height
    ]);
    const minimumHeights = positions.map((p: any) => alitude);

    dataSource.entities.add({
      cut,
      kind: 'cut',
      show: cut.show,
      wall: {
        positions,
        material: color,
        depthFailMaterial: color,
        minimumHeights,
      }
    });
  }

  createElevationEntity(dataSource: any, elevation: Elevation, displayCameraEntity: boolean = false): void {
    const color = new Cesium.ColorMaterialProperty(new Cesium.CallbackProperty(() => {
      let selectedId = undefined;
      this.store.select(selectCurrentElevationId)
        .pipe(first()).subscribe((result: any) =>selectedId = result);
      const selectedColor = (elevation.id == selectedId) ? Color.TRIADIC_2 : Color.TRIADIC_1;
      const color = Cesium.Color.fromCssColorString(selectedColor);
      return color.withAlpha(0.7);
    }, false));
    const size = 0.1;
    const length = size*6;
    const cartographic = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromArray(elevation.lastPosition));
    const translation = new Cesium.Cartesian3(0, 0, length / 2.0);
    const shiftedPosition = new Cesium.Cartesian3(cartographic.longitude, cartographic.latitude, cartographic.height);
    const positionWGS84 = Cesium.Cartesian3.add(shiftedPosition, translation, new Cesium.Cartesian3());
    const positionECEF = Cesium.Cartesian3.fromRadians(positionWGS84.x, positionWGS84.y, positionWGS84.z);
    const entity: any = {
      elevation,
      kind: 'elevation',
      show: elevation.show,
      position: positionECEF,
      cylinder: {
        bottomRadius: 0,
        topRadius: size,
        length: length,
        material: color,
        depthFailMaterial: color,
      },
    }
    dataSource.entities.add(entity);

    if (displayCameraEntity) {
      this.createCameraEntity(
        dataSource,
        elevation.center,
        elevation.cameraDirection,
        2);
    }
  }
}
