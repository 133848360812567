import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { Site } from '../../../models/site';
import { InspectionModule } from '../../../enums/inspection-module';
import { combineLatest, Observable } from 'rxjs';
import {
  selectDetectionTotal
} from '../../../features/detection/detection.selectors';
import { selectVolumeTotal } from '../../../features/volume/volume.selectors';
import {
  selectMeasureTotal
} from '../../../features/measure/measure.selectors';
import { selectMarkerTotal } from '../../../features/marker/marker.selectors';
import { selectCutTotal } from '../../../features/cut/cut.selectors';
import {
  selectConfigSiteDetailSelectedSection, selectConfigViewRepresentation
} from '../../../features/config/config.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  setConfigSiteDetailSelectedSection
} from '../../../features/config/config.actions';
import { InspectionModulePipe } from '../../../shared/pipes/module.pipe';
import {
  selectElevationTotal
} from '../../../features/elevation/elevation.selectors';
import { ViewRepresentation } from '../../../enums/representation';
import { selectCurrentBatch } from '../../../features/batch/batch.selectors';
import {
  loadDetectionEmergencyTemplate,
  loadDetections, loadDetectionSummary,
  loadDetectionTemplate
} from '../../../features/detection/detection.actions';
import {
  loadVolumeEmergencyTemplate,
  loadVolumes, loadVolumeSummary
} from '../../../features/volume/volume.actions';
import { Batch } from '../../../models/batch';

@Component({
  selector: 'site-details-content',
  templateUrl: './site-details-content.component.html',
  styleUrls: ['./site-details-content.component.scss']
})
export class SiteDetailsContentComponent {

  inspectionModule = InspectionModule;
  viewRepresentation = ViewRepresentation;
  currentListSelected: number | undefined = 0;
  site: Site | null | undefined;

  constructor(private store: Store, private modulePipe: InspectionModulePipe) {
    this.initHandleConfig();
    this.initHandleSiteAndBatch();
  }

  private initHandleSiteAndBatch(): void {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectCurrentBatch)
    ]).pipe(takeUntilDestroyed())
      .subscribe(results => {
        const site: Site | null | undefined = results[0];
        const batch: Batch | null | undefined = results[1];
        if (site) {
          const isDetectionAvailable = this.modulePipe.transform(site, [InspectionModule.ARCAD]);
          const isVolumeAvailable = this.modulePipe.transform(site, [InspectionModule.PERREAD]);
          if (isDetectionAvailable){
            this.store.dispatch(setConfigSiteDetailSelectedSection({value: 0}));
          }
          else if (isVolumeAvailable){
            this.store.dispatch(setConfigSiteDetailSelectedSection({value: 1}));
          }
          else {
            this.store.dispatch(setConfigSiteDetailSelectedSection({value: 2}));
          }
        }
        if (site && batch) {
          if (this.modulePipe.transform(site, [InspectionModule.ARCAD])) {
            this.store.dispatch(loadDetections({
              organizationId: site.organization,
              siteId: site.id,
              batchId: site.lastBatch
            }));
            this.store.dispatch(loadDetectionTemplate({
              organizationId: site.organization,
              siteId: site.id
            }));
            this.store.dispatch(loadDetectionEmergencyTemplate({
              organizationId: site.organization,
              siteId: site.id
            }));
            this.store.dispatch(loadDetectionSummary({
              organizationId: site.organization,
              siteId: site.id,
              batchId: site.lastBatch,
              comparedToBatchId: batch.id
            }));
          }

          if (this.modulePipe.transform(site, [InspectionModule.PERREAD])) {
            this.store.dispatch(loadVolumes({
              organizationId: site.organization,
              siteId: site.id,
              batchId: site.lastBatch,
              threshold: 1,
              step: 1,
              comparedToBatchId: batch.id
            }));
            this.store.dispatch(loadVolumeSummary({
              organizationId: site.organization,
              siteId: site.id,
              batchId: site.lastBatch,
              comparedToBatchId: batch.id
            }));
            this.store.dispatch(loadVolumeEmergencyTemplate({
              organizationId: site.organization,
              siteId: site.id
            }));
          }
        }
      });
  }

  private initHandleConfig(): void {
    this.store.select(selectConfigViewRepresentation)
      .pipe(takeUntilDestroyed())
      .subscribe(value => {
        if((this.currentListSelected == 5 && value != ViewRepresentation.DETECTIONS)
          || (this.currentListSelected == 6 && value != ViewRepresentation.VOLUMES)) {
          this.selectList(1);
        }
      });
    this.store.select(selectConfigSiteDetailSelectedSection)
      .pipe(takeUntilDestroyed())
      .subscribe(value => {
        this.currentListSelected = value;
      });
  }

  get site$(): Observable<Site | null | undefined> {
    return this.store.select(selectCurrentSite);
  }

  get totalDetection$(): Observable<number> {
    return this.store.select(selectDetectionTotal);
  }

  get totalVolume$(): Observable<number> {
    return this.store.select(selectVolumeTotal);
  }

  get totalMeasure$(): Observable<number> {
    return this.store.select(selectMeasureTotal);
  }

  get totalMarker$(): Observable<number> {
    return this.store.select(selectMarkerTotal);
  }

  get totalCut$(): Observable<number> {
    return this.store.select(selectCutTotal);
  }

  get totalElevation$(): Observable<number> {
    return this.store.select(selectElevationTotal);
  }

  selectList(index: number): void {
    this.store.dispatch(setConfigSiteDetailSelectedSection({value: index}));
  }

  get selectedViewRepresentation$(): Observable<number> {
    return this.store.select(selectConfigViewRepresentation);
  }
}
