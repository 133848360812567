import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as MeasureActions from './measure.actions';
import { Measure } from '../../models/measure';

export interface State extends EntityState<Measure> {
  selectedId: string | undefined;
}

export const adapter: EntityAdapter<Measure> = createEntityAdapter<Measure>();

export const initialState: State = adapter.getInitialState({selectedId: undefined});

const measureReducer = createReducer(
  initialState,
  on(MeasureActions.loadMeasures, (state) => {
    return state;
  }),
  on(MeasureActions.loadMeasuresSuccess, (state, { measures }) => {
    return adapter.setAll(measures, state);
  }),
  on(MeasureActions.loadMeasuresFailure, (state, { error }) => {
    return state;
  }),
  on(MeasureActions.addMeasureDistance, (state ) => {
    return state;
  }),
  on(MeasureActions.addMeasureArea, (state ) => {
    return state;
  }),
  on(MeasureActions.addMeasureSuccess, (state, { measure }) => {
    return adapter.setOne(measure, state);
  }),
  on(MeasureActions.addMeasureFailure, (state, { error }) => {
    return state;
  }),
  on(MeasureActions.updateMeasure, (state) => {
    return state;
  }),
  on(MeasureActions.updateMeasureSuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(MeasureActions.updateMeasureFailure, (state) => {
    return state;
  }),
  on(MeasureActions.deleteMeasure, (state) => {
    return state;
  }),
  on(MeasureActions.deleteMeasureSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(MeasureActions.deleteMeasureFailure, (state, { error }) => {
    return state;
  }),
  on(MeasureActions.clearAllMeasures, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(MeasureActions.selectMeasure, (state, {id}) => {
    return { ...state, selectedId: id };
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return measureReducer(state, action);
}

export const getSelectedMeasureId = (state: State) => state.selectedId;
