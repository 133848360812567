import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  setCameraConfiguration,
  setCameraMode,
} from '../../../../features/cesium/cesium.actions';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { EmergencyTools } from '../../../../tools/emergency.tools';
import { Elevation } from '../../../../models/elevation';
import {
  selectElevation
} from '../../../../features/elevation/elevation.actions';
import { Observable } from 'rxjs';
import { User } from '../../../../models/user';
import { selectUser } from '../../../../features/user/user.selectors';

@Component({
  selector: 'elevation-card',
  templateUrl: './elevation-card.component.html',
  styleUrls: ['../card.component.scss'],
})
export class ElevationCardComponent {

  @Input({required: true}) elevation: Elevation | undefined;

  constructor(private store: Store) {
  }

  get border() {
    return EmergencyTools.border(0);
  }

  onClickFlyTo(elevation: Elevation): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(elevation.center, elevation.cameraDirection, elevation.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickEdit(elevation: Elevation): void {
    this.store.dispatch(selectElevation({id: elevation.id}));
  }

  get currentUser$(): Observable<User | undefined> {
    return this.store.select(selectUser)
  }
}
