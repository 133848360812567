import {
  Component,
} from '@angular/core';
import {
  animate,
  animateChild,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';


@Component({
  selector: 'view-configuration',
  templateUrl: './view-configuration.component.html',
  styleUrls: ['./view-configuration.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ top: '-400px'}),
        query('@*', animateChild(), { optional: true }),
        animate('350ms ease-out', style({ top: '25px'})),
      ]),
      transition(':leave', [
        style({ top: '25px'}),
        animate('300ms ease-in', style({ top: '-400px'}))
      ]),
    ]),
  ],
})
export class ViewConfigurationComponent {

}
