import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import {
  DateFormatPipe,
  DateSincePipe,
  DurationPipe, ECEFToWGS84Pipe, ItemNamePipe,
} from './pipes/tools.pipe';
import { ProductPermissionPipe } from './pipes/product.pipe';
import {
  UserConfigurationErrorDialog
} from './dialogs/user-configuration-error-dialog/user-configuration-error.dialog';
import { ConfirmDialog } from './dialogs/confirm-dialog/confirm.dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SearchPipe } from './pipes/search.pipe';
import { CesiumDirective } from './directives/cesium';
import { CesiumMiniMapDirective } from './directives/cesium-minimap';
import {
  Cesium2dvControlComponent
} from './components/cesium-2dv-control/cesium-2dv-control.component';
import { InspectionModulePipe } from './pipes/module.pipe';
import {
  FormatDetectionModePipe,
  FormatDetectionStatusPipe
} from './pipes/detection.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  DetectionListComponent
} from './components/item-lists/detection-list/detection-list.component';
import {
  MarkerListComponent
} from './components/item-lists/marker-list/marker-list.component';
import {
  CutListComponent
} from './components/item-lists/cut-list/cut-list.component';
import {
  MeasureListComponent
} from './components/item-lists/measure-list/measure-list.component';
import {
  VolumeListComponent
} from './components/item-lists/volume-list/volume-list.component';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import {
  VolumeColorRampComponent
} from './components/volume-color-ramp/volume-color-ramp.component';
import { NgChartsModule } from 'ng2-charts';
import {
  DetectionCardComponent
} from './components/item-cards/detection-card/detection-card.component';
import {
  VolumeCardComponent
} from './components/item-cards/volume-card/volume-card.component';
import {
  VolumeDisplayModeSelectorComponent
} from './components/volume-display-mode/volume-display-mode-selector.component';
import {
  VolumeThresholdFormComponent
} from './components/volume-threshold-form/volume-threshold-form.component';
import {
  CutChartComponent
} from './components/charts/cut-chart.component';
import {
  CutDetailsComponents
} from './components/item-details/cut-details/cut-details.components';
import {
  ComputeLoaderComponent
} from './components/loaders/compute-loader/compute-loader.component';
import {
  MeasureDetailsComponents
} from './components/item-details/measure-details/measure-details.components';
import {
  DetectionDetailsComponents
} from './components/item-details/detection-details/detection-details.components';
import {
  MarkerDetailsComponents
} from './components/item-details/marker-details/marker-details.components';
import {
  VolumeDetailsComponents
} from './components/item-details/volume-details/volume-details.components';
import {
  MeasureCardComponent
} from './components/item-cards/measure-card/measure-card.component';
import {
  CutCardComponent
} from './components/item-cards/cut-card/cut-card.component';
import {
  MarkerCardComponent
} from './components/item-cards/marker-card/marker-card.component';
import {
  DensityChartComponent
} from './components/charts/density-chart.component';
import { FiltersPipe } from './pipes/filters.pipe';
import { SectorPipe } from './pipes/sector.pipe';
import {
  FilterSliderComponent
} from './components/filter-slider/filter-slider.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import {
  HistoryChannelComponents
} from './components/history-channel/history-channel.components';
import {
  DeleteConfirmDialog
} from './dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { RenameDialog } from './dialogs/rename-dialog/rename.dialog';
import { SearchComponent } from './components/item-search/search.component';
import {
  SiteSearchComponent
} from './components/item-search/site-search/site-search.component';
import {
  DeleteDetectionDialog
} from './dialogs/delete-detection-dialog/delete-detection.dialog';
import {
  CreateDetectionDialog
} from './dialogs/create-detection-dialog/create-detection.dialog';
import { ReportDialog } from './dialogs/report-dialog/report.dialog';
import {
  LeaveSiteDialog
} from './dialogs/leave-site-dialog/leave-site.dialog';
import {
  PicturesViewerDialog
} from './dialogs/pictures-viewer-dialog/pictures-viewer.dialog';
import { MarkerSortDistancePipe } from './pipes/marker.pipe';
import {
  PictureComponent
} from './components/picture/picture.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxPanZoomModule } from 'ngx-panzoom';
import {
  WorldSearchComponent
} from './components/item-search/world-search.component.ts/world-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  OrganizationDialog
} from './dialogs/organization-dialog/organization.dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslocoModule } from '@jsverse/transloco';
import {
  SiteActivationDialog
} from './dialogs/site-activation-dialog/site-activation.dialog';
import {
  SiteDeactivationDialog
} from './dialogs/site-deactivation-dialog/site-deactivation.dialog';
import {
  CutHistoryChannelComponents
} from './components/history-channel/cut-history-channel.component';
import {
  MarkerHistoryChannelComponents
} from './components/history-channel/marker-history-channel.component';
import {
  MeasureHistoryChannelComponents
} from './components/history-channel/measure-history-channel.component';
import {
  TilesetConfigSelectorComponent
} from './components/tileset-config-selector/tileset-config-selector.component';
import {
  BatchDiffRepresentationSelectorDialog
} from './dialogs/batch-diff-representation-selector-dialog/batch-diff-representation-selector.dialog';
import {
  ElevationListComponent
} from './components/item-lists/elevation-list/elevation-list.component';
import {
  ElevationCardComponent
} from './components/item-cards/elevation-card/elevation-card.component';
import {
  ElevationDetailsComponents
} from './components/item-details/elevation-details/elevation-details.components';
import {
  ElevationHistoryChannelComponents
} from './components/history-channel/elevation-history-channel.component';
import {
  ElevationChartComponent
} from './components/charts/elevation-chart.component';
import {
  TilesetDiffColorRampComponent
} from './components/tileset-diff-color-ramp/tileset-diff-color-ramp.component';
import {
  TilesetStyleFormComponent
} from './components/tileset-style-form/tileset-style-form.component';
import {
  ComparedBatchSelectorComponent
} from './components/compared-batch-selector/compared-batch-selector.component';
import {
  ViewConfigurationComponent
} from './components/view-configuration/view-configuration.component';
import {
  ViewRepresentationSelectorComponent
} from './components/view-configuration/view-representation-selector/view-representation-selector.component';
import {
  ViewModeSelectorComponent
} from './components/view-configuration/view-mode-selector/view-mode-selector.component';
import {
  DrawEntitiesToolbarComponent
} from './components/entities-toolbar/draw-entities-toolbar.component';
import {
  CreatePerreadPipelineDialog
} from './dialogs/create-perread-pipeline-dialog/create-perread-pipeline.dialog';


@NgModule({
  declarations: [
    TilesetConfigSelectorComponent,
    WorldSearchComponent,
    SearchComponent,
    SiteSearchComponent,
    HistoryChannelComponents,
    CutHistoryChannelComponents,
    MarkerHistoryChannelComponents,
    MeasureHistoryChannelComponents,
    ElevationHistoryChannelComponents,
    FilterSliderComponent,
    DensityChartComponent,
    MarkerCardComponent,
    CutCardComponent,
    MeasureCardComponent,
    VolumeDetailsComponents,
    MarkerDetailsComponents,
    DetectionDetailsComponents,
    MeasureDetailsComponents,
    ElevationDetailsComponents,
    ComputeLoaderComponent,
    VolumeThresholdFormComponent,
    VolumeDisplayModeSelectorComponent,
    Cesium2dvControlComponent,
    ViewModeSelectorComponent,
    VolumeColorRampComponent,
    CutDetailsComponents,
    CutListComponent,
    DetectionListComponent,
    MarkerListComponent,
    MeasureListComponent,
    VolumeListComponent,
    ElevationListComponent,
    CutChartComponent,
    ElevationChartComponent,
    DetectionCardComponent,
    VolumeCardComponent,
    ElevationCardComponent,
    PictureComponent,
    TilesetDiffColorRampComponent,
    TilesetStyleFormComponent,
    ComparedBatchSelectorComponent,
    ViewRepresentationSelectorComponent,
    ViewConfigurationComponent,
    DrawEntitiesToolbarComponent,
    ConfirmDialog,
    DeleteConfirmDialog,
    RenameDialog,
    UserConfigurationErrorDialog,
    DeleteDetectionDialog,
    CreateDetectionDialog,
    ReportDialog,
    LeaveSiteDialog,
    PicturesViewerDialog,
    OrganizationDialog,
    SiteActivationDialog,
    SiteDeactivationDialog,
    BatchDiffRepresentationSelectorDialog,
    CreatePerreadPipelineDialog,
    DateSincePipe,
    DateFormatPipe,
    DurationPipe,
    ProductPermissionPipe,
    SearchPipe,
    FiltersPipe,
    SectorPipe,
    InspectionModulePipe,
    FormatDetectionModePipe,
    FormatDetectionStatusPipe,
    CesiumDirective,
    CesiumMiniMapDirective,
    MarkerSortDistancePipe,
    ItemNamePipe,
    ECEFToWGS84Pipe,
    ViewModeSelectorComponent
  ],
  exports: [
    TilesetConfigSelectorComponent,
    WorldSearchComponent,
    SearchComponent,
    SiteSearchComponent,
    HistoryChannelComponents,
    CutHistoryChannelComponents,
    MarkerHistoryChannelComponents,
    MeasureHistoryChannelComponents,
    ElevationHistoryChannelComponents,
    FilterSliderComponent,
    DensityChartComponent,
    VolumeDetailsComponents,
    MarkerDetailsComponents,
    ElevationDetailsComponents,
    DetectionDetailsComponents,
    MeasureDetailsComponents,
    ComputeLoaderComponent,
    VolumeThresholdFormComponent,
    VolumeDisplayModeSelectorComponent,
    Cesium2dvControlComponent,
    ViewModeSelectorComponent,
    VolumeColorRampComponent,
    CutDetailsComponents,
    CutListComponent,
    DetectionListComponent,
    MarkerListComponent,
    MeasureListComponent,
    VolumeListComponent,
    ElevationListComponent,
    CutChartComponent,
    ElevationChartComponent,
    DetectionCardComponent,
    VolumeCardComponent,
    ElevationCardComponent,
    PictureComponent,
    TilesetDiffColorRampComponent,
    TilesetStyleFormComponent,
    ComparedBatchSelectorComponent,
    ViewConfigurationComponent,
    DrawEntitiesToolbarComponent,
    ConfirmDialog,
    DeleteConfirmDialog,
    RenameDialog,
    LeaveSiteDialog,
    UserConfigurationErrorDialog,
    DeleteDetectionDialog,
    CreateDetectionDialog,
    ReportDialog,
    PicturesViewerDialog,
    OrganizationDialog,
    SiteActivationDialog,
    SiteDeactivationDialog,
    BatchDiffRepresentationSelectorDialog,
    CreatePerreadPipelineDialog,
    DateSincePipe,
    DateFormatPipe,
    DurationPipe,
    SearchPipe,
    FiltersPipe,
    SectorPipe,
    InspectionModulePipe,
    ProductPermissionPipe,
    FormatDetectionModePipe,
    FormatDetectionStatusPipe,
    MarkerSortDistancePipe,
    ItemNamePipe,
    ECEFToWGS84Pipe,
    CesiumDirective,
    CesiumMiniMapDirective,
  ],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatTooltipModule,
        MatDialogModule,
        MatExpansionModule,
        MatInputModule,
        MatButtonToggleModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatCardModule,
        MatBadgeModule,
        MatSliderModule,
        MatMenuModule,
        MatTabsModule,
        ReactiveFormsModule,
        NgChartsModule,
        NgxPanZoomModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        TranslocoModule
    ],
  providers: [
    DateSincePipe,
    DateFormatPipe,
    DurationPipe,
    SearchPipe,
    FiltersPipe,
    SectorPipe,
    ProductPermissionPipe,
    InspectionModulePipe,
    FormatDetectionModePipe,
    FormatDetectionStatusPipe,
    MarkerSortDistancePipe,
    ItemNamePipe,
    ECEFToWGS84Pipe
  ]
})
export class SharedModule { }
