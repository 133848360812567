<div class="container" *ngIf="site">
  <mat-form-field>
    <mat-label>{{'representation' | transloco}}</mat-label>
    <mat-select [formControl]="representationControl" (selectionChange)="onChangeRepresentation($event)">
      <mat-option [value]="viewRepresentation.DEFAULT">{{'representation_default' | transloco}}</mat-option>
      <mat-option *ngIf="(site | module: [inspectionModule.ARCAD]) && detectionSummary"
                  [value]="viewRepresentation.DETECTIONS">{{'representation_detections' | transloco}}</mat-option>
      <mat-option *ngIf="(site | module: [inspectionModule.PERREAD]) && volumeSummary"
                  [value]="viewRepresentation.VOLUMES">{{'representation_volumes' | transloco}}</mat-option>
      <mat-option *ngIf="batchs.length > 1"
                  [value]="viewRepresentation.DIFFERENTIAL">{{'representation_differential' | transloco}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
