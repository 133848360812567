<div class="container">
  <div *ngIf="searchBarDisplayed" class="search">
    <world-search></world-search>
  </div>
  <div *ngIf="!searchBarDisplayed">
    <button mat-icon-button class="material-symbols-outlined"
            matTooltip="{{'straight_line_measurement' | transloco}}"
            (click)="drawLine($event)"
            [disabled]="isComputing"
            [color]="(isDrawingLine) ? 'accent' : 'primary'">straighten</button>
    <button mat-icon-button class="material-symbols-outlined"
            matTooltip="{{'measuring_area_and_volume' | transloco}}"
            (click)="drawPolygon($event)"
            [disabled]="isComputing"
            [color]="(isDrawingPolygon) ? 'accent' : 'primary'">activity_zone</button>
    <button mat-icon-button class="material-symbols-outlined"
            matTooltip="{{'draw_cut' | transloco}}"
            (click)="drawCut($event)"
            [disabled]="isComputing"
            [color]="(isDrawingCut) ? 'accent' : 'primary'">landscape</button>
    <button mat-icon-button class="material-symbols-outlined"
            matTooltip="{{'put_elevation' | transloco}}"
            (click)="drawElevation($event)"
            [disabled]="isComputing"
            [color]="(isDrawingElevation) ? 'accent' : 'primary'">altitude</button>
    <button mat-icon-button class="material-symbols-outlined"
            matTooltip="{{'put_marker' | transloco}}"
            [disabled]="isComputing"
            (click)="drawMarker($event)"
            [color]="(isDrawingMarker) ? 'accent' : 'primary'">radio_button_checked</button>
    <button *ngIf="site && (site | module: [inspectionModule.ARCAD])"
            mat-icon-button class="material-symbols-outlined"
            matTooltip="{{'create_new_detection' | transloco}}"
            (click)="drawNewDetection($event)"
            [disabled]="isComputing"
            [color]="(isDrawingNewDetection) ? 'accent' : 'primary'">capture</button>
  </div>
  <div style="display:flex; flex-direction: row;">
    <div class="vertical-separator"></div>
    <button mat-icon-button class="material-symbols-outlined"
            matTooltip="{{'search' | transloco}}"
            (click)="toggleSearch($event)"
            [color]="(searchBarDisplayed) ? 'accent' : 'primary'">search</button>
  </div>
</div>




