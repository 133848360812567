<div *ngIf="(site$ | async) as site" class="container">
  <div class="actions">
    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 1}"
         (click)="selectList(1)">
      <mat-icon matTooltip="{{'measures' | transloco}}">
        design_services
        <div class="badge"
             *ngIf="(totalMeasure$ | async) as totalMeasure"
             [ngClass]="{'badge-active': currentListSelected == 1}"
             [hidden]="totalMeasure==0">{{totalMeasure}}</div>
      </mat-icon>
    </div>

    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 2}"
         (click)="selectList(2)">
      <mat-icon matTooltip="{{'cuts' | transloco}}">
        landscape
        <div class="badge"
             *ngIf="(totalCut$ | async) as totalCut"
             [ngClass]="{'badge-active': currentListSelected == 2}"
             [hidden]="totalCut==0">{{totalCut}}</div>
      </mat-icon>
    </div>

    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 3}"
         (click)="selectList(3)">
      <mat-icon matTooltip="{{'elevations' | transloco}}">
        altitude
        <div class="badge"
             *ngIf="(totalElevation$ | async) as totalElevation"
             [ngClass]="{'badge-active': currentListSelected == 3}"
             [hidden]="totalElevation==0">{{totalElevation}}</div>
      </mat-icon>
    </div>

    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 4}"
         (click)="selectList(4)">
      <mat-icon matTooltip="{{'markers' | transloco}}">
        radio_button_checked
        <div class="badge"
             *ngIf="(totalMarker$ | async) as totalMarker"
             [ngClass]="{'badge-active': currentListSelected == 4}"
             [hidden]="totalMarker==0">{{totalMarker}}</div>
      </mat-icon>
    </div>

    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 5}"
         (click)="selectList(5)"
         *ngIf="(site | module: [inspectionModule.ARCAD])
         && (selectedViewRepresentation$ | async) == viewRepresentation.DETECTIONS">
      <mat-icon matTooltip="{{'detections' | transloco}}">
        stacks
        <div class="badge"
             *ngIf="(totalDetection$ | async) as totalDetection"
             [ngClass]="{'badge-active': currentListSelected == 5}"
             [hidden]="totalDetection==0">{{totalDetection}}</div>
      </mat-icon>
    </div>

    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 6}"
         (click)="selectList(6)"
         *ngIf="site.previousBatch && (site | module: [inspectionModule.PERREAD])
         && (selectedViewRepresentation$ | async) == viewRepresentation.VOLUMES">
      <mat-icon matTooltip="{{'volumes' | transloco}}">
        deployed_code
        <div class="badge"
             *ngIf="(totalVolume$ | async) as totalVolume"
             [ngClass]="{'badge-active': currentListSelected == 6}"
             [hidden]="totalVolume==0">{{totalVolume}}</div>
      </mat-icon>
    </div>

  </div>


  <div class="lists">

    <measure-list class="list"
                  [ngClass]="{'list-selected': currentListSelected == 1}">
    </measure-list>

    <cut-list class="list"
              [ngClass]="{'list-selected': currentListSelected == 2}">
    </cut-list>

    <elevation-list class="list"
                    [ngClass]="{'list-selected': currentListSelected == 3}">
    </elevation-list>

    <marker-list class="list"
                 [ngClass]="{'list-selected': currentListSelected == 4}">
    </marker-list>

    <detection-list class="list"
                    [ngClass]="{'list-selected': currentListSelected == 5}">
    </detection-list>

    <volume-list class="list"
                 [ngClass]="{'list-selected': currentListSelected == 6}">
    </volume-list>

  </div>


</div>
