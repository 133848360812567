<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>cloud</mat-icon>&nbsp;{{'batch_diff_selector_dialog_title' | transloco}}
    </mat-card-title>
<!--    <mat-card-subtitle>{{'batch_diff_selector_dialog_subtitle' | transloco}}</mat-card-subtitle>-->
  </mat-card-header>
  <mat-card-content>
    <div class="content">
      <div>{{'batch_diff_selector_dialog_message' | transloco}}</div>
      <div *ngIf="(batchs$ | async) as batchs">
        <mat-form-field>
          <mat-label>{{'batch_reference' | transloco}}</mat-label>
          <mat-select [disabled]="isDisabled"
                      [formControl]="batchReferenceControl">
            @for (batch of batchs; track batch) {
            <mat-option [value]="batch.id" [disabled]="batch.id == batchReferenceControl.value">{{batch.dateAcquired | date:dateFormat}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="(batchs$ | async) as batchs">
        <mat-form-field>
          <mat-label>{{'batch_compared' | transloco}}</mat-label>
          <mat-select [disabled]="isDisabled"
                      [formControl]="batchComparedControl">
            @for (batch of batchs; track batch) {
            <mat-option [value]="batch.id" [disabled]="batch.id == batchComparedControl.value">{{batch.dateAcquired | date:dateFormat}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close()">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            [disabled]="!batchComparedControl.value || !batchReferenceControl.value"
            (click)="dialogRef.close(
            {'first': batchComparedControl.value, 'second': batchReferenceControl.value
            })">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
