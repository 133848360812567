import {
  Component, Input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Batch } from '../../../models/batch';
import { selectAllBatchs } from '../../../features/batch/batch.selectors';
import { Site } from '../../../models/site';
import { selectUser } from '../../../features/user/user.selectors';
import { User } from '../../../models/user';
import { FormControl } from '@angular/forms';
import { selectBatch } from '../../../features/batch/batch.actions';



@Component({
  selector: 'compared-batch-selector',
  templateUrl: './compared-batch-selector.component.html',
  styleUrls: ['./compared-batch-selector.component.scss'],
})
export class ComparedBatchSelectorComponent {

  @Input() site: Site | undefined
  user: User | null | undefined;
  comparedBatchs: Array<Batch> | undefined;
  batchControl = new FormControl('');

  constructor(private store: Store) {
    this.initHandleUser();
    this.initHandleBatch();
  }

  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      });
  }

  private initHandleBatch(): void {
    this.store.select(selectAllBatchs)
      .pipe(takeUntilDestroyed())
      .subscribe(batchs => {
        this.batchControl.setValue(null);
        if (this.site && batchs) {
          this.comparedBatchs = batchs.filter((b) => b.id != this.site?.lastBatch);
          if (this.comparedBatchs.length) {
            this.batchControl.setValue(this.comparedBatchs[0].id);
            this.store.dispatch(selectBatch({id: this.comparedBatchs[0].id}));
          }
        }
        else {
          this.comparedBatchs = undefined;
        }
      });
  }

  get dateFormat() {
    if(this.user) {
      return (this.user.languageCode == 'fr') ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    }
    return 'MM/dd/yyyy';
  }

  onChangeBatch(event: any): void {
    this.store.dispatch(selectBatch({id: event.value}));
  }

}
