<div class="inspection-container">
  <div class=side
       [ngClass]="{
    'side-expanded': sideMode === sideModeEnum.MODE_EXPANDED,
    'side-collapsed' : sideMode === sideModeEnum.MODE_COLLAPSED}"
       [@sideExpandedCollpased]="sideMode === sideModeEnum.MODE_EXPANDED ? 'expanded' : 'collapsed'">

    <site-filters *ngIf="displaySitesFilters"></site-filters>

    <div class="side-header" *ngIf="!displaySitesFilters">
      <div class="main">
        <button mat-icon-button (click)="toggleSideMode()">
          <mat-icon>menu</mat-icon>
        </button>
        <div *ngIf="sideMode === sideModeEnum.MODE_EXPANDED && (organization$| async) as organization" class="organization">{{organization.name}}</div>
      </div>
      <site-list-header *ngIf="sideMode == sideModeEnum.MODE_EXPANDED && !(site$ | async)"></site-list-header>
      <site-details-header *ngIf="sideMode == sideModeEnum.MODE_EXPANDED && (site$ | async)" (close)="close($event)"></site-details-header>
    </div>

    <div class="side-expanded-content side-expanded-content-with-actions"
         *ngIf="sideMode == sideModeEnum.MODE_EXPANDED && !displaySitesFilters">
      <site-list *ngIf="!(site$ | async)"></site-list>
      <site-details-content *ngIf="(site$ | async)" (close)="close($event)"></site-details-content>
    </div>

    <div class="side-expanded-all-site-actions" *ngIf="sideMode === sideModeEnum.MODE_EXPANDED && !displaySitesFilters && !(site$ | async)">
      <div *ngIf="(organizationCount$ | async) as count">
        <button *ngIf="count > 1"
                mat-icon-button
                matTooltip="{{'select_organization' | transloco}}" (click)="displayOrganizations()">
          <mat-icon>compare_arrows</mat-icon>
        </button>
      </div>
      <button *ngIf="(user$ | async) as user"
              mat-icon-button
              matTooltip="{{'logout' | transloco}}" (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>

    <div class="side-expanded-site-actions"
         *ngIf="sideMode === sideModeEnum.MODE_EXPANDED && !displaySitesFilters && (site$ | async) as site"
         [ngClass]="{
        'side-expanded-site-actions-space-between': site.isActivableByUser,
        'side-expanded-site-actions-right' : !site.isActivableByUser}">
      <button *ngIf="site.isActivableByUser"
              mat-icon-button
              matTooltip="{{ 'deactivate' | transloco }}"
              (click)="onClickDeactivateSite(site)">
        power_settings_new
      </button>
      <button mat-icon-button
              matTooltip="{{'generate_report' | transloco}}" (click)="report()">
        assignment
      </button>
    </div>

    <div class="side-collapsed-actions" *ngIf="sideMode === sideModeEnum.MODE_COLLAPSED && !(site$ | async)">
      <button *ngIf="(user$ | async) as user"
              mat-icon-button
              matTooltip="{{'logout' | transloco}}" (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>

  </div>

  <div class="main-content"
       [ngClass]="{
       'main-content-side-is-expanded': sideMode === sideModeEnum.MODE_EXPANDED,
       'main-content-side-is-collapsed': sideMode === sideModeEnum.MODE_COLLAPSED}">
    <world></world>
  </div>

</div>
