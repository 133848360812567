import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromVolume from './volume.reducer';

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromVolume.adapter.getSelectors();

export const selectVolumeState = createFeatureSelector<fromVolume.State>('volumes');

export const selectVolumeIds = createSelector(
  selectVolumeState,
  selectIds
);
export const selectVolumeEntities = createSelector(
  selectVolumeState,
  selectEntities
);
export const selectAllVolumes = createSelector(
  selectVolumeState,
  selectAll
);
export const selectVolumeTotal = createSelector(
  selectVolumeState,
  selectTotal
);


export const selectVolumeColorMode = createSelector(
  selectVolumeState,
  state => state.colorMode
);


export const selectCurrentVolumeId = createSelector(
  selectVolumeState,
  fromVolume.getSelectedVolumeId
);

export const selectCurrentVolume = createSelector(
  selectVolumeEntities,
  selectCurrentVolumeId,
  (entities, id) => (id) ? entities[id] : null
);

export const selectVolumeEmergencyTemplate = createSelector(
  selectVolumeState,
  fromVolume.getVolumeEmergencyTemplate
);

export const selectVolumeSummary = createSelector(
  selectVolumeState,
  fromVolume.getVolumeSummary
);



