import {
  Component,
  HostListener, inject,
  ViewChild
} from '@angular/core';
import { Cut, CutBatchData } from '../../../../models/cut';
import { Store } from '@ngrx/store';
import {
  deleteCut, exportCutDXF, exportCutDXFSuccess,
  selectCut,
  updateCut
} from '../../../../features/cut/cut.actions';
import { selectCurrentCut } from '../../../../features/cut/cut.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectMeasure } from '../../../../features/measure/measure.actions';
import { selectVolume } from '../../../../features/volume/volume.actions';
import {
  selectMarker,
} from '../../../../features/marker/marker.actions';
import {
  selectDetection
} from '../../../../features/detection/detection.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { RenameDialog } from '../../../dialogs/rename-dialog/rename.dialog';
import {
  setCameraConfiguration,
  setCameraMode,
  setMapHandleKeyboard
} from '../../../../features/cesium/cesium.actions';
import { CutChartComponent } from '../../charts/cut-chart.component';
import { ExportTools } from '../../../../tools/export.tools';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { TranslocoService } from '@jsverse/transloco';
import { combineLatest, first, Observable } from 'rxjs';
import { selectUser } from '../../../../features/user/user.selectors';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Site } from '../../../../models/site';
import { Serialize } from 'cerialize';
import { User } from '../../../../models/user';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  selectElevation
} from '../../../../features/elevation/elevation.actions';
import { FormBuilder } from '@angular/forms';
import {
  selectCurrentBatch,
  selectCurrentBatchId
} from '../../../../features/batch/batch.selectors';
import { Batch } from '../../../../models/batch';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'cut-details',
  templateUrl: './cut-details.component.html',
  styleUrls: ['../details.component.scss', './cut-details.component.scss'],
  animations: [
    trigger('displayMode', [
      state('expanded', style({
        right: '10px',
      })),
      state('collapsed', style({
        width: '400px',
      })),
      transition('expanded => collapsed', [
        animate('150ms ease-out'),
      ]),
      transition('collapsed => expanded', [
        animate('150ms ease-out'),
      ])
    ]),
  ],
})
export class CutDetailsComponents {

  displayModeExtended: boolean = false;
  cut: Cut | null | undefined;
  user: User | null | undefined;
  @ViewChild('graph') graph: CutChartComponent | undefined;
  private deleteDialogRef: MatDialogRef<DeleteConfirmDialog> | undefined;
  private renameDialogRef: MatDialogRef<RenameDialog> | undefined;
  private slope:Array<Array<number>> | undefined;
  private readonly _formBuilder = inject(FormBuilder);

  readonly batchFormGroup = this._formBuilder.group({

  });

  constructor(private store: Store,
              private actions: Actions,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    this.initHandleUser();
    this.store.select(selectCurrentCut)
      .pipe(takeUntilDestroyed())
      .subscribe((cut: Cut | null | undefined) => {
        this.cut = cut;
        if (this.cut) {
          this.slope = this.cut.slope;
          this.store.dispatch(selectMeasure({id: undefined}));
          this.store.dispatch(selectVolume({id: undefined}));
          this.store.dispatch(selectMarker({id: undefined}));
          this.store.dispatch(selectDetection({id: undefined}));
          this.store.dispatch(selectElevation({id: undefined}));
        }
      });
    this.actions
      .pipe(ofType(exportCutDXFSuccess), takeUntilDestroyed())
      .subscribe((data: any) => {
        if (this.cut) {
          ExportTools.exportDXF(this.cut.name, data['dxf']);
        }
      });
  }

  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      });
  }

  get horizontalDistance(): number | undefined {
    if (this.slope) {
      const x1 = this.slope[0][0];
      const x2 = this.slope[this.slope.length-1][0];
      return Math.abs(x1 - x2);
    }
    return undefined
  }

  get verticalDistance(): number | undefined {
    if (this.slope) {
      const y1 = this.slope[0][1];
      const y2 = this.slope[this.slope.length - 1][1];
      return Math.abs(y1 - y2);
    }
    return undefined;
  }

  get angle(): number | undefined {
    if (this.verticalDistance && this.horizontalDistance){
      return Math.atan2(this.verticalDistance, this.horizontalDistance) * 180.0 / Math.PI;
    }
    return undefined;
  }

  private resize(): void {
    setTimeout(()=>{
      if (this.graph) this.graph.resize();
    }, 1);
  }

  onSlopeDragging(slope: Array<Array<number>> | undefined): void {
    this.slope = slope;
  }


  onSlopeHasChanged(slope: Array<Array<number>> | undefined): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (this.cut && site) {
          this.store.dispatch(updateCut({
            organizationId: site.organization,
            siteId: site.id,
            cutId: this.cut.id,
            payload: {
              slope: slope
            }
          }));
        }
      });
  }

  onHandleFocus(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }

  onClickFlyTo(cut: Cut): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(cut.center, cut.cameraDirection, cut.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickClose(): void {
    this.store.dispatch(selectCut({id: undefined}));
    this.displayModeExtended = false;
  }

  onClickDelete(cut: Cut): void {
    this.deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    this.deleteDialogRef.componentInstance.message = this.translocoService.translate("would_you_like_to_delete", {name: cut.name});
    this.deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(deleteCut({
                organizationId: site.organization,
                siteId: site.id,
                cutId: cut.id
              }));
            }
          });
      }
    });
  }

  onClickExportGraph(cut: Cut): void {
    if (this.graph) {
      this.graph.canvasToImage(cut.name);
    }
  }

  onClickExportData(cut: Cut): void {
    ExportTools.exportJSON(cut.name, JSON.stringify(cut));
  }

  onClickExportDXF(cut: Cut): void {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectUser)
    ]).pipe(first())
      .subscribe(contents => {
        const site: any = contents[0];
        const user = contents[1];
        if (site && user && this.cut && this.slope) {
          const cuts:Array<any> = this.cut?.data.map((profil: CutBatchData) => Serialize(profil));
          this.store.dispatch(exportCutDXF({
            organizationId: site.organization,
            siteId: site.id,
            cuts,
            slope: this.slope,
            language_code: user.languageCode
          }));
        }
      });
  }

  onClickRename(cut: Cut): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
    this.renameDialogRef = this.dialog.open(RenameDialog, {
      disableClose: false
    });
    this.renameDialogRef.componentInstance.placeholder = cut.name;
    this.renameDialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
      if(result && this.cut) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(updateCut({
                organizationId: site.organization,
                siteId: site.id,
                cutId: cut.id,
                payload: {
                  name: result,
                  slope: cut.slope,
                  is_shared: cut.isShared
                }}));
            }
          });
      }
    });
  }

  onClickToggleShare(cut: Cut): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.store.dispatch(updateCut({
            organizationId: site.organization,
            siteId: site.id,
            cutId: cut.id,
            payload: {
              name: cut.name,
              slope: cut.slope,
              is_shared: !cut.isShared
            }}));
        }
      });
  }

  onClickToggleExtend(): void {
    this.displayModeExtended = !this.displayModeExtended;
  }

  onAnimationDone(): void {
    this.resize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.resize();
  }

  get currentSite$(): Observable<Site | null | undefined> {
    return this.store.select(selectCurrentSite)
  }

  get currentBatch$(): Observable<Batch | null | undefined> {
    return this.store.select(selectCurrentBatch)
  }

  formatDate(date:any | undefined): string | null {
    if(!date) return null;
    let dateFormat;
    if(this.user) {
      dateFormat = this.user.languageCode == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    }else {
      dateFormat = 'MM/dd/yyyy';
    }
    const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
    return datePipe.transform(new Date(date), dateFormat);
  }

  toggleHiddenChartDataset(completed: boolean, index: number) {
    if (this.graph) {
      this.graph.setChartDatasetVisible(index, completed);
    }
  }
}


