import { Injectable } from '@angular/core';
import {
  FormatDetectionModePipe,
  FormatDetectionStatusPipe
} from '../shared/pipes/detection.pipe';
import { Site } from '../models/site';
import { Store } from '@ngrx/store';
import { selectCurrentSite } from '../features/site/site.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectDrawingMode } from '../features/cesium/cesium.selectors';
import { DrawingMode } from '../enums/drawing-mode';
import { DetectionStatus } from '../enums/detection';
import { TranslocoService } from '@jsverse/transloco';
import { SiteStatus } from '../enums/site';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  private siteSelected: Site | null | undefined;
  private drawingMode: DrawingMode | undefined;

  constructor(private store: Store,
              private formatDetectionStatusPipe: FormatDetectionStatusPipe,
              private formatDetectionModePipe: FormatDetectionModePipe,
              private translocoService: TranslocoService) {
    this.initHandleSite();
    this.initHandleDrawingMode();
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        this.siteSelected = site;
      });
  }

  private initHandleDrawingMode(): void {
    this.store.select(selectDrawingMode)
      .pipe(takeUntilDestroyed())
      .subscribe((mode: DrawingMode | undefined) => {
        this.drawingMode = mode;
        document.body.style.cursor = (this.drawingMode) ? 'crosshair' : 'default';
      });
  }

  private static setOverlayPosition(viewer:any, overlay: any, position: any): void {
    overlay.style.display = 'block';
    overlay.style.borderRadius = '6px';
    overlay.style.padding = '0';
    overlay.style.margin = '0';
    overlay.style.bottom = `${
      viewer.canvas.clientHeight - position.y
    }px`;
    overlay.style.left = `${position.x + 10}px`;
  }

  displayFeatureContent(viewer:any, overlay: any, feature: any, position: any): void {
    if(this.drawingMode) return;
    if (!feature) {
      document.body.style.cursor = 'default';
      overlay.style.display = 'none';
      return;
    }
    const isSelected = feature.primitive.isSelected || false;
    if (isSelected) {
      document.body.style.cursor = 'default';
      overlay.style.display = 'none';
      return;
    }

    const entity = feature.id || feature.primitive.entity;
    if (!entity?.kind) {
      document.body.style.cursor = 'default';
      overlay.style.display = 'none';
      return;
    }

    switch (entity.kind) {
      case 'site': {
        if(this.siteSelected) break;
        document.body.style.cursor = (entity.site.status == SiteStatus.UPDATING || entity.site.status == SiteStatus.RUNNING) ? 'pointer' : 'default';
        overlay.innerText = entity.site.name
        overlay.innerHTML =
          "<div class='popup-title'>" + entity.site.name + "</div>"
        OverlayService.setOverlayPosition(viewer, overlay, position);
        break;
      }
      case 'detection': {
        document.body.style.cursor = 'pointer';
        const status = (entity.status == DetectionStatus.TO_BE_CONFIRMED) ? this.formatDetectionStatusPipe.transform(entity.status) : entity.emergency.label;
        const confidence = (entity.confidence) ? entity.confidence.toFixed(2) * 100.0 : 0;
        const evolution = (entity.evolution) ? entity.evolution.toFixed(2) * 100.0 : 0;
          overlay.innerHTML =
          "<div class='popup-title'>" + entity.name + "</div>" +
          "<table class='popup-content'>" +
          "<tr><td>"+this.translocoService.translate('status')+":</td><td class='popup-value'>" + status +"</td><tr>" +
          "<tr><td>"+this.translocoService.translate('mode')+":</td><td class='popup-value'>" + this.formatDetectionModePipe.transform(entity.createMode) +"</td><tr>" +
          "<tr><td>"+this.translocoService.translate('confidence')+":</td><td class='popup-value'>" + confidence +" &#37;</td><tr>" +
          "<tr><td>"+this.translocoService.translate('evolution')+":</td><td class='popup-value'>" + evolution +" &#37;</td><tr>" +
          "</table>"
        OverlayService.setOverlayPosition(viewer, overlay, position);
        break;
      }
      case 'volume': {
        document.body.style.cursor = 'pointer';
        const status = (entity.volume.emergency) ? entity.volume.emergency.label : undefined;
        if (status) {
          overlay.innerHTML =
            "<div class='popup-title'>" + entity.volume.name + "</div>" +
            "<table class='popup-content'>" +
            "<tr><td>"+this.translocoService.translate('status')+":</td><td class='popup-value'>" + status +"</td><tr>" +
            "<tr><td>"+this.translocoService.translate('volume')+":</td><td class='popup-value'>" + entity.volume.value.toFixed(2) +" m3</td><tr>" +
            "</table>"
        }
        else {
          overlay.innerHTML =
            "<div class='popup-title'>" + entity.volume.name + "</div>" +
            "<table class='popup-content'>" +
            "<tr><td>"+this.translocoService.translate('volume')+":</td><td class='popup-value'>" + entity.volume.value.toFixed(2) +" m3</td><tr>" +
            "</table>"
        }
        OverlayService.setOverlayPosition(viewer, overlay, position);
        break;
      }
      case 'measure': {
        document.body.style.cursor = 'pointer';
        overlay.innerHTML = "<div class='popup-title'>" + entity.measure.name + "</div>";
        if (entity.measure.areaVolume) {
          overlay.innerHTML +=
            "<table class='popup-content'>" +
            "<tr><td>"+this.translocoService.translate('projected_area')+":</td><td class='popup-value'>" + entity.measure.areaVolume.projectedArea.toFixed(2) +" m2</td><tr>" +
            "<tr><td>"+this.translocoService.translate('estimate_of_actual_area')+":</td><td class='popup-value'>" + entity.measure.areaVolume.terrainArea.toFixed(2) +" m2</td><tr>" +
            "<tr><td>"+this.translocoService.translate('full_volume_above_the_average_plan')+":</td><td class='popup-value'>" + entity.measure.areaVolume.upFullVolume.toFixed(2) +" m3</td><tr>" +
            "<tr><td>"+this.translocoService.translate('full_volume_below_the_average_plan')+":</td><td class='popup-value'>" + entity.measure.areaVolume.downFullVolume.toFixed(2)+" m3</td><tr>" +
            "<tr><td>"+this.translocoService.translate('empty_volume_below_average_plan')+":</td><td class='popup-value'>" + entity.measure.areaVolume.downEmptyVolume.toFixed(2)+" m3</td><tr>" +
            "</table>";
        }
        else if (entity.measure.straightMeasure) {
          overlay.innerHTML +=
            "<table class='popup-content'>" +
            "<tr><td>"+this.translocoService.translate('direct_distance')+":</td><td class='popup-value'>" + entity.measure.straightMeasure.directDistance.toFixed(2) +" m</td><tr>" +
            "<tr><td>"+this.translocoService.translate('distance_follwing_the_terrain_from_above')+":</td><td class='popup-value'>" + entity.measure.straightMeasure.topTerrainDistance.toFixed(2) +" m</td><tr>" +
            "<tr><td>"+this.translocoService.translate('distance_follwing_the_ground_from_the_front')+":</td><td class='popup-value'>" + entity.measure.straightMeasure.faceTerrainDistance.toFixed(2) +" m</td><tr>" +
            "</table>";
        }
        OverlayService.setOverlayPosition(viewer, overlay, position);
        break;
      }
      case 'cut': {
        document.body.style.cursor = 'pointer';
        overlay.innerHTML = "<div class='popup-title'>" + entity.cut.name + "</div>";
        OverlayService.setOverlayPosition(viewer, overlay, position);
        break;
      }
      case 'marker': {
        document.body.style.cursor = 'pointer';
        overlay.innerHTML = "<div class='popup-title'>" + entity.marker.name + "</div>";
        OverlayService.setOverlayPosition(viewer, overlay, position);
        break;
      }
      case 'elevation': {
        document.body.style.cursor = 'pointer';
        overlay.innerHTML = "<div class='popup-title'>" + entity.elevation.name + "</div>";
        OverlayService.setOverlayPosition(viewer, overlay, position);
        break;
      }
      default:
        overlay.style.display = 'none';
        break
    }
  }

}
