<mat-card *ngIf="site" [style.border]=site.border>
  <div class="card-status"
       [style.background-color]=site.backgroundColor
       [style.color]=site.textColor>
    <span *ngIf="!siteAvailable">
      <span *ngIf="!siteInActivation">{{'not_available' | transloco}}</span>
      <span *ngIf="siteInActivation">{{'in_activation' | transloco}}</span>
    </span>
    <span *ngIf="siteAvailable">
      <span *ngIf="site.emergencyLevel > 0">{{site.emergencyLabel}}</span>
      <span *ngIf="site.emergencyLevel == 0">{{'available' | transloco}}</span>
    </span>
  </div>

  <mat-card-header>
    <mat-card-title>
      {{site.name}}
    </mat-card-title>
    <mat-card-subtitle>
      {{site | sector}}
    </mat-card-subtitle>
  </mat-card-header>

  <mat-divider *ngIf="site | module: [inspectionModule.ARCAD, inspectionModule.PERREAD]"></mat-divider>

  <mat-card-content class=modules>
    <div *ngIf="(site | module: [inspectionModule.ARCAD]) && site.detectionSummary">
      <site-detection-summary [site]="site"></site-detection-summary>
    </div>
    <div *ngIf="(site | module: [inspectionModule.PERREAD]) && site.volumeSummary" class=module>
      <site-volume-summary [volumeSummary]="site.volumeSummary"></site-volume-summary>
    </div>
  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-card-actions>
    <button mat-icon-button matTooltip="{{ 'move_on_site' | transloco}}" (click)="onClickFlyToSite(site)">location_on</button>
    <button mat-icon-button *ngIf="siteAvailable" matTooltip="{{ 'inspect_site' | transloco }}" (click)="onClickSelectSite(site)">my_location</button>
    <button mat-icon-button *ngIf="!siteAvailable && !siteInActivation && site.isActivableByUser" matTooltip="{{ 'activate' | transloco }}" (click)="onClickActivateSite(site)">power_settings_new</button>
  </mat-card-actions>

</mat-card>
